.submenu {
    @include xy-cell(12, $gutters: 0);
    @include show-for(smedium);
}

.submenu__level1 {
    &>li {
        @include flex;

        &>a {
            @include xy-cell(2);
        }
    }
}

.submenu__level2 {
    @include xy-cell(auto);
    @include flex;
    @include flex-align(left, top);
    flex-wrap: wrap;

    li {
        @include flex;
        @include flex-align(left, top);
    }
}

.submenu__level3 {
    @include xy-cell(auto);
    @include flex;
    @include flex-align(left, top);
    flex-wrap: wrap;
}

.submenu__w-button {
    @include flex;
    @include flex-align(center, middle);
}