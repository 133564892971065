/**
 * Checkout component
 * &--complete : complete checkout step
 */
.checkout.checkout--complete {
	.checkout__submit {
		display: block !important;
		text-align: center;
	}

	.checkout__submitbutton {
		@include project-button;
		margin-top: 1.2rem;
		font-size: rem-calc(20px);
		line-height: rem-calc(24px);
		
		@include breakpoint(small only) {
			width: 100%;
		}

		@include breakpoint(smedium) {
			padding: 1rem 7rem;
		}

		&--large {
			@include breakpoint(smedium) {
				padding: 1rem 4rem;
			}
		}
	}
}