/**
 * Footer component
 */
.footer {
	@include xy-grid-container;
	@include xy-grid;

	@include breakpoint(smedium down) {
		@include xy-grid-container($padding: 0);
	}
}

.footer__address {
	@include breakpoint(smedium down) {
		@include xy-cell(full);
	}
}

.footer__aside {
	@include breakpoint(large) {
		@include xy-cell(auto, $gutters: 350px, $gutter-position: left);
	}

	@include breakpoint(medium only) {
		@include xy-cell(auto, $gutters: 300px, $gutter-position: left);
	}

	@include breakpoint(smedium down) {
		@include xy-cell(full, $gutters: 0);
	}
}

.footer__brands {

	&:not(&--responsive) {
		@include show-for(medium);
	}

	&--responsive {
		@include hide-for(medium);
	}
}

.footer__company {
	@include xy-cell(auto, $gutters: 25px, $gutter-position: left);

	@include breakpoint(medium) {
		@include xy-cell(auto, $gutters: 86px, $gutter-position: left);
	}
}

.footer__contactbutton {
	@include breakpoint(smedium down) {
		@include xy-cell(auto);
	}
}

.footer__helptext {
	@include breakpoint(smedium down) {
		@include xy-cell(full);
	}
}

.footer__main {
	@include xy-grid;
	@include xy-cell(100%);

	@include breakpoint(medium) {
		@include xy-cell(60%, $gutters: 0);
	}
}

.footer__phone {
	@include breakpoint(smedium down) {
		@include xy-cell(full);
	}
}

.footer__wrapper {
	@include xy-cell(100%, $gutters: 0);
	@include xy-grid;
	@include flex-align(justify);
}
