.modal.modal--cartsummary {
	.modal__aside {
		top:0;
		position: sticky;

		&::before {
	    	@include breakpoint(large) {
				content: "";
		    	width: 30px;
				height: 30px;
		    	display: block;
		    	background: url(../../img/icons/cart-white.svg) no-repeat;
		    	margin-left: auto;
		    	margin-right: auto;
		    	margin-bottom: 2rem;
	    	}
		}
	}
}