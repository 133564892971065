// Tingle modal
.tingle-modal {
	@include breakpoint(smedium down) {
		padding-top: 0;
	}
	
	&--notification {
		background: none;
	}

	&--menu {
		.tingle-modal-box {
			margin-top: 0;

			@include breakpoint(medium) {
				padding-top: 5%;
			}
		}
	}

	&--search {
		.tingle-modal-box {
			margin-top: 0;

			@include breakpoint(medium) {
				padding-top: 5%;
			}
		}
	}

	&--overflow {
		padding-top: 0;
	}
}

.tingle-modal__close {
	display: none;

}