/**
 * topbar component
 */
.topbar__buttons {
	@include xy-grid;
	@include flex-align(justify);
	@include flex-align(right, middle);
}

.topbar__content {
	@include xy-grid-container;
	@include xy-grid;
	@include flex-align(justify);
}

.topbar__accountbutton {
	@include flex;
	@include flex-align(left, middle);
}

.topbar__cartbutton {
	@include flex;
	@include flex-align(left, middle);
}

.topbar__menu {
	@include xy-grid;
	@include flex-align(justify, middle);
}

.topbar__searchbutton {
	@include flex;
	@include flex-align(left, middle);
}