/**
 * Cart component
 * Display by pages: cart.html
 * Display by partials: cart.html
 */
.cart {
	margin-bottom: 2.1rem;
}

.cart__items {
	padding-top: 1rem;
}

.cart__aside {
	margin-top: 2.4rem;
}

.cart__discountdelete {
	margin-left: 1rem;
	flex: 1 1 100%;
	text-decoration: underline;
	cursor: pointer;
}

.cart__discountform {
	@include breakpoint(medium) {
		max-width: 60%;
	}

	&--withdiscount {
		@include breakpoint(medium) {
			max-width: 100%;
		}
	}
	
	input {
		margin: 0 0.5rem 0 0;
	}
	
	*[type="submit"] {
		@include project-button;
		padding-left: 1rem;
		padding-right: 1rem;
		margin-bottom: 0
	}
}

.cart__discounttitle {
	margin-top: 2rem;
	margin-bottom: 0.5rem;
	font-family: $subtitle-font-family;
	font-weight: bold;
	font-style: italic;
}

.cart__main {
	&--empty {
		margin-top: 2rem;
		color: $jaffa;
	}
}

.cart__orderbutton {
	@include project-button;
	width: 100%;
	font-size: rem-calc(14px);
}

.cart__backbutton {
	@include project-button(white, white, black);
	width: 100%;
	font-size: rem-calc(14px);
	border: 1px solid black;
}

.cart__title {
	text-transform: uppercase;
	margin-top: 2.9rem;
}

.cart__subtitle {
	text-transform: uppercase;
	font-size: 1.2rem;
	text-decoration: underline;
	margin-top: 2rem;
}

.cart__updatecart {
	@include project-button;
	margin-top: 1rem;
}