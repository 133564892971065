.cartsummary.cartsummary--products {
	padding-bottom: 3.3rem;

	.cartsummary__price {
		font-family: $title-font-family;
		font-size: rem-calc(16px);
		text-align: right;
	}
	
	.cartsummary__product {
		font-size: rem-calc(14px);
		line-height: rem-calc(16px);

		&--heading {
			@include project-subtitle;
			font-weight: bold;
			margin-bottom: 1.5rem;

			.cartsummary__name, .cartsummary__quantity, .cartsummary__price {
				@include project-subtitle;
				font-size: rem-calc(14px);
				line-height: rem-calc(16px);
			}
		}
	}

	.cartsummary__quantity {
		font-size: rem-calc(16px);
		font-family: $title-font-family;
		text-align: center;
	}
}