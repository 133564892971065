/**
 * Reassuranceitem component
 * Display by pages: index.html
 * Display by partials: homereassurance.html
 */
.reassuranceitem {
	text-align: center;
	font-size: rem-calc(13px);
	font-family: $subtitle-font-family;
	color: 	$black;

	@include breakpoint(small only) {
		margin-bottom: 1.4rem;
	}

	&::before {
		content: "";
    	width: 35px;
		height: 35px;
    	display: inline-block;
    	margin-bottom: 1rem;

    	@include breakpoint(small only) {
    		margin-bottom: 0.3em;
    	}
	}

	span {
		font-size: rem-calc(16px);
		font-family: $title-font-family;
		display: block;
		text-transform: uppercase;
		margin-bottom: 0;
	}

	&--small {
		span {
			font-size: rem-calc(14px);
		}

		&::before {
			width: 30px;
			height: 30px;
		}
	}

	&--shop {
		&::before {
			background: url(../../img/icons/shop.svg) no-repeat;
			width: 40px;
		}
	}

	&--samples {
		&::before {
			background: url(../../img/icons/samples.svg) no-repeat;
			width: 44px;
		}
	}

	&--shipping {
		&::before {
			background: url(../../img/icons/package.svg) no-repeat;
		}
	}

	&--lock {
		&::before {
			background: url(../../img/icons/lock.svg) no-repeat;
			height: 30px;
		}
	}
}