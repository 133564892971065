/**
 * Homereassurance component
 * Display by pages: index.html
 * Display by partials: _instagram.html
 */
.homeinstagram {
	@include xy-grid-container($padding: 0);
	@include xy-grid;
	@include project-sectiontitle;
	padding-bottom: 0.3rem;
	margin-top: 3rem;

	&::before {
		margin-bottom: 3rem;
	}

	@include breakpoint(smedium down) {
		padding-top: 0;
	}
}

.homeinstagram__content {
	@include xy-cell(100%, $gutters: 0);
}

.homeinstagram__header {
	margin-top: 0 !important;

	@include breakpoint(smedium) {
		margin-bottom: 2rem;
	}

	@include breakpoint(smedium down) {
		margin-top: 0;
	}

	@include breakpoint(small only) {
		margin-bottom: 1.5rem;
	}


}

.homeinstagram__title {
	padding-top: 0 !important;

	&:before {
		@include dotBefore();
	}

	@include breakpoint(small only) {
		max-width: 70%;
	}
}