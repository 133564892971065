/**
 * Cartsummary component
 * Display by pages: cart.html, checkout-complete.html
 * Display by partials: cartsummary.html
 * Contexts :
 * &--complete : complete checkout step
 */
.cartsummary {
	padding: 1.2rem 1.5rem 1.5rem;
	border: 1px solid $border-color;
	margin-bottom: 1rem;
}

.cartsummary__item {
	font-size: rem-calc(16px);
	
	&:last-child {
		margin-top: 1rem;
		
		.cartsummary__itemlabel {
			font-weight: bold;
			text-transform: uppercase;
		}
		.cartsummary__itemvalue {
			font-size: rem-calc(24px);
		}
	}
}

.cartsummary__itemvalue {
	font-family: $title-font-family;
	font-size: rem-calc(17px);
	text-transform: uppercase;
}

.cartsummary__title {
	font-family: $subtitle-font-family;
	font-size: rem-calc(20px);
	font-style: italic;	
	text-align: center;
	position: relative;
	padding-bottom: 0.7rem;
	margin-bottom: 2rem;
	font-weight: normal;	

	&::after {
		content: "";
		height: 1px;
		width: 40%;
		background: $border-color;
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
	}
}