/**
 * Cart component
 */
.cart {
	@include xy-grid-container;
	@include xy-grid;
}

.cart__aside {
	@include xy-cell(12, $gutters: 0);
	
	@include breakpoint(medium ) {
		@include xy-cell(auto, $gutters: 80px, $gutter-position: left);
	}
}

.cart__discountform {
	@include flex;
	@include flex-align(left, middle);
}

.cart__discounerrors {
	@include xy-cell(12, $gutters: 0);
}

.cart__main {
	@include xy-cell(12, $gutters: 0);
	
	@include breakpoint(medium ) {
		@include xy-cell(8, $gutter-position: right);
	}
}

.cart__title {
	@include xy-cell(100%, false);
}