/**
 * Steps component
 * Display by pages: checkout-address.html, checkout-complete.html
 * Display by partials: steps.html
 */
.steps {
	margin-top: 0.8rem;
		
	@include breakpoint(smedium) {
		margin-top: 3rem;
	}
}

.steps__current {
	font-family: $subtitle-font-family;
	font-size: rem-calc(16px);
	font-style: italic;
	color: $body-font-color;
	font-weight: 100;
	font-style: italic;
	border-top: 1px solid $border-color;
	border-bottom: 1px solid $border-color;
	padding: 0.4rem 0.3rem 0.5rem;
}

.steps__previous {
	font-family: $subtitle-font-family;
	font-size: rem-calc(16px);
	font-style: italic;
	color: $body-font-color;
	font-weight: 100;
	font-style: italic;

	&::before {
		content: "";
		background: url('../../img/icons/chevron.svg') no-repeat;
		width: 11px;
		height: 18px;
		margin: 0 0.2rem 0 0; 
		transform: rotate(-180deg);
	}
}