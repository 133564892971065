/**
 * Checkout component
 * &--address : address checkout step
 */
.checkout.checkout--address {
	margin-top: 2.5rem;
	margin-bottom: 8rem;

	@include breakpoint(smedium) {
		margin-bottom: 14.5rem;
	}

	.checkout__addressform {
		margin-bottom: 2rem;
	}

	.form__field.checkbox {
		margin-top: 2.5rem;

		@include breakpoint(small only) {
			margin-bottom: 2rem;
		}
	}

	.checkout__submit {
		margin-top: 2rem;

		@include breakpoint(smedium) {
			margin-top: 3rem;
		}
	}

	.checkout__title {
		margin-top: 0;
		margin-bottom: 1.2rem;
	}

}