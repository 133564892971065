.modal.modal--notification {
	background: white;
	height: 100%;
	padding: 3rem 2rem;
	width: 30%;

	@include breakpoint(small only) {
		width: 95%;
	}

	@include breakpoint(smedium only) {
		width: 75%;
	}

	@include breakpoint(medium only) {
		width: 50%;
	}

	@include breakpoint(large only) {
		width: 40%;
	}

	@include breakpoint(xlarge only) {
		width: 50%;
	}
	
	.modal__button {
		@include project-button;
		width: auto;
		margin-top: 2rem;
	}
	
	.modal__header {
		margin-bottom: 1rem;
	}
	
	.modal__main {
		color: $heavymetal;
		text-align: center;
	}

	.modal__title {
		color: $heavymetal;
		margin-top: 1.5rem;
		text-align: center;
	}
}

.modal.modal--error {
	border: 1px solid $jaffa;

	.modal__header {
		&::before {
			content: "";
			background: url('../../img/icons/warning.svg') no-repeat;
			width: 41px;
			height: 36px;
		}
	}
}

.modal.modal--success {
	border: 1px solid $downy;

	.modal__header {
		&::before {
			content: "";
			background: url('../../img/icons/success.svg') no-repeat;
			width: 41px;
			height: 36px;
		}
	}
}