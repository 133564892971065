/**
 * Footernav component
 */
.footernav__wrapper {
	@include xy-grid-container;
	@include xy-grid;

	a {
		@include breakpoint(smedium down) {
			@include xy-cell(100%, $gutters: 0);
		}
	}
}