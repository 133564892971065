/**
 * Cartitem component
 * Display by pages: cart.html, checkout-complete.html
 * Display by partials: cartiem.html
 * Modifiers :
 * &--heading : First line, cartitem heading
 * &--responsive : responsive display
 */
.cartitem {
	border-bottom: 1px solid $border-color;
	padding-top: 0.8rem;
	padding-bottom: 0.8rem;

	&:nth-child(2) {
		@include breakpoint(smedium down) {
			border-top: 1px solid $border-color;
		}
	}

	&--heading {
		padding: 0;

		&>* {
			font-family: $subtitle-font-family !important;
			font-style: italic;
			font-weight: bold;
			font-size: rem-calc(14px) !important;
			margin-bottom: 0.3rem;
		}
	}
}

.cartitem__amount {
	@include breakpoint(smedium down) {
		margin-top: 0.4rem;
	}
}
.cartitem__amount, .cartitem__price, .cartitem__quantity {
	font-size: rem-calc(16px);
	font-family: $title-font-family;

	@include breakpoint(medium) {
		text-align: center;
	}

	@include breakpoint(smedium down) {
		line-height: rem-calc(18px);
	}

	span {
		font-family: $subtitle-font-family;
		font-weight: bold;
		font-style: italic;
		font-size: rem-calc(14px);
	}

	input {
		margin-bottom: 0
	}
}

.cartitem__buttondelete {
	background: url('../../img/icons/trash.svg') no-repeat;
	width: 19px;
	height: 21px;
	display: inline-block;
	cursor: pointer;
}

.cartitem__delete {
	text-align: right;

	@include breakpoint(medium) {
		text-align: center;
	}
}

.cartitem__image {
	max-width: 95px;
	img {
		max-width: 84px;
	}
}

.cartitem__name {
	&>a {
		font-size: rem-calc(16px);
		font-family: $title-font-family;
		text-transform: uppercase;
		display: block;
		color: $heavymetal;
		font-weight: 100;

		@include breakpoint(smedium down) {
			line-height: rem-calc(16px);
		}
	}
}

.cartitem__originalprice {
	text-decoration: line-through;
}

.cartitem__price {
	@include breakpoint(smedium down) {
		margin-top: 0.8rem;
	}
}

.cartitem__quantity {
	@include breakpoint(smedium down) {
		max-width: 100px;
	}

	@include breakpoint(smedium down) {
		margin-top: 0.4rem;
	}

	span {
		@include breakpoint(smedium down) {
			margin-bottom: 0.4rem;
			display: block;
		}
	}
}
