/**
 * Menumainmain component
 * Display by pages: all
 * Display by partials: header.html
 */
.menumain {
	padding-bottom: 0.6rem;

	@include breakpoint(smedium) {
		border-bottom: 1px solid $border-color;
	}

	&--home {
		@include breakpoint(smedium) {
			border-bottom: none;
		}

		.menumain__item {
			text-shadow: #000 8px 1px 10px;
		}
	}

	@include breakpoint(small only) {
		display: none;
	}
}

.menumain__item {
	text-transform: uppercase;
	color: $body-font-color;
	padding: 1rem;
	font-family: $title-font-family;
	font-size: rem-calc(18px);

	&[data-menu-item="blog"] {
		display: none;
	}

	&[data-menu-item="cms/a.propos"] {
		display: none;
	}
}