/**
 * Sharebuttons component
 * Display by pages: product.html, category.html
 * Display by partials: product.html, category.html
 */
.sharebuttons {
	a {
		height: 30px;
    	width: 30px;
    	display: inline-block;
    	background: url(../../img/icons/facebook-circle.svg);

    	&:last-child {
    		background: url(../../img/icons/pinterest-circle.svg);
    		margin-left: 1rem;
    	}
	}
}
