/**
 * Header component
 * Home context
 */
.header.header--home {
	@include breakpoint(medium) {
		padding-bottom: 3rem;
	}
	
	.header__navigation {
		a {
			color: white;
		}
	}

	.header__searchform {
		@include breakpoint(medium) {
			margin-top: 3.8rem;
		}

		@include breakpoint(smedium down) {
			background: white;
		}

		input {
			margin-bottom: 0;
		}
	}

	.header__wrapper {
		padding-top: 5rem;
		padding-bottom: 0.2rem;

		@include breakpoint(smedium down) {
			padding-top: 3rem;
			padding-bottom: 1.6rem;
		}

		a {
			width: 205px;
			height: 205px;

			@include breakpoint(smedium down) {
				width: 160px;
				height: 160px;
			}
		}
	}
}