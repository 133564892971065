// Tingle modal box
.tingle-modal-box {
	background: none;
	color: white;
	width: 100%;
	transition: transform .3s;
  	transform: scale(.9);
	
	.product__title:not(.product__title--bigger) {
		color: white;
	}

	.product__subtitle:not(.product__subtitle--bigger) {
		color: white;
	}

	.product__price:not(.product__price--bigger) {
		color: white;
	}

	.product__oldprice:not(.product__oldprice--bigger) {
		color: white;
	}
}

.tingle-modal-box__button {
	@include project-button;

	&--negative {
		@include project-negative-button;
	}
}

.tingle-modal-box__content {
  	padding: 2rem 0rem;
}

.tingle-modal-box__footer {
	background: none;

	&::after {
		display: none;
	}
}
