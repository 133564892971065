/**
 * Steps component
 */
.steps {
	@include xy-grid-container;
	@include xy-grid;
	@include flex-align(justify, middle);

	@include breakpoint(smedium) {
		@include flex-align(center, middle);
	}
}

.steps__current {
	@include hide-for(smedium)
}

.steps__previous {
	@include xy-grid;
	@include hide-for(smedium)
}