.cartnotice {
  border-top: 2px solid $jaffa;
  border-bottom: 2px solid $jaffa;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 10px;
  text-align: center;
  text-transform: uppercase;
}

// .cartnotice {
//   @include xy-grid-container;
//   @include xy-grid;
// }

// .cartnotice__wrapper {
//   @include xy-cell($gutters: 0);
//   @include flex;
//   @include flex-align(left, middle);
// }