/**
 * Flash component
 * Display by pages: all
 * Display by partials: flash.html
 */
.flash {
	margin-top: 1.8rem;
	margin-bottom: 1.8rem;

	@include breakpoint(medium) {
		margin-top: 3.1rem;
	}
}

.flash__wrapper {
	border: 1px solid $border-color;
	padding: 0.5rem 2rem 1rem;
	font-family: $subtitle-font-family;
	font-size: rem-calc(16px);
	line-height: rem-calc(19px);
	font-style: italic;

	@include breakpoint(medium) {
		padding: 1.4rem 2rem 1rem;
	}

	&--error, &--negative {
		border-color: $jaffa;
		
		&::before {
			content: "";
			background: url('../../img/icons/warning.svg') no-repeat;
			width: 41px;
			height: 36px;
			margin-right: 1rem;
		}
	}

	&--success, &--positive {
		border-color: $downy;

		&::before {
			content: "";
			background: url('../../img/icons/success.svg') no-repeat;
			width: 21px;
			height: 16px;
			margin-right: 1rem;
		}
	}
}