/**
 * Product component
 */
.product__flag {
	// @include responsive-display(&);
}

.product__label {
	@include xy-cell(40%, $gutter-position: right);
}

.product__option {
	@include flex;
	@include flex-align(right, middle);
}

.product__responsiveheader {
	@include xy-grid;
	@include xy-cell(100%, $gutters: 0);
	@include flex-align(justify, middle);
	@include hide-for(smedium);
}

.product__sharebuttons {
	@include flex;
	@include flex-align(right);
}