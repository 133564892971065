/**
 * Breadcrumb component
 * Display by pages: all excepted index.html
 * Used on partials: breadcrumb.html
 * Modifiers: []
 */
.breadcrumb {
	padding-top: 1.7rem; 
	@include show-for(smedium);

	ul {
		list-style: none;
		margin: 0 0 1.8rem;
	}

	a, span {
		color: $body-font-color;
		
		&::after {
			content: ">";
			padding-left: 0.25rem;
			padding-right: 0.25rem;
		}
	}
	
	// Last item
	li:last-child {
		span {
			font-weight: bold;

			&::after {
				content: none;
			}
		}
	}
}