.order__pay {
	&--withmargin {
		margin-top: 2rem;
	}
}

.order__paybutton {
	display: inline-block;
	@include project-button;
	font-size: 1.4rem;
	line-height: 2rem;
}

.order__summaryitem {
	display: block;

	&:last-child {
		margin-bottom: 1rem;
	}

	label {
		font-size: 1rem;
	}
}