.searchformmodal__button {
	@include project-button($background: white, $background-hover: white, $color: $heavymetal);
	border: 1px solid white;
	height: 2.6rem;
}

.searchformmodal__textbutton {
	@include project-button;
	border: 1px solid white;
	height: 2.6rem;
	width: 100%
}