/**
 * Homenetworks component
 * Display by pages: index.html
 * Display by partials: homecontact.html
 */
.homenetworks__info {
	display: block;
	font-family: $subtitle-font-family;

	@include breakpoint(smedium down) {
		margin-bottom: 1.8rem;
	}
}

.homenetworks__items {
	margin-top: 0.8rem;
	padding-bottom: 0.2rem;
	border-top: 1px solid $border-color;
	border-bottom: 1px solid $border-color;
	display: inline-block;

	@include breakpoint(smedium down) {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}

	a {
		height: 23px;
		width: 23px;
		display: inline-block;
		background: url(../../img/icons/facebook-circle.svg) no-repeat;
		margin-top: 0.6rem;

		@include breakpoint(smedium down) {
			height: 38px;
			width: 38px;
		}
		
		&:first-child {
			margin-left: 0.75rem;
			background: url(../../img/icons/facebook-circle.svg) no-repeat;
			margin-right: 0.8rem;

			@include breakpoint(smedium down) {
				margin-right: 0.5rem;
				margin-left: 0.5rem;
			}
		}


		&:last-child {
			margin-left: 0.75rem;
			margin-right: 0.75rem;
			background: url(../../img/icons/instagram-circle.svg) no-repeat;
			
			@include breakpoint(smedium down) {
				margin-left: 0.2rem;
				margin-right: 0.5rem;
			}
		}
	}
}

.homenetworks__title {
	font-family: $title-font-family;
	font-size: rem-calc(20px);
	padding-top: 0.9rem;
	margin-bottom: 1rem;

	&--responsive {
		margin-bottom: 0.5rem;
	}
}