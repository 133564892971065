/**
 * Pagination component
 * Display by pages: category.html
 * Display by partials: pagination.html
 */
.pagination {
	margin: 0;
	font-family: $title-font-family;
	margin-top: 2rem;

	li {
		list-style: none;
	}
}

.pagination__item, .pagination .item {
	margin-left: 1rem;
	margin-right: 1rem;
	font-size: rem-calc(16px);

	&--current, &.active {
		color: $white;
		position: relative;
		z-index: 2;
			
		&::after {
			content: "";
			width: 35px;
			height: 35px;	
			border-radius: 50%;
			padding: 0.2rem;
			background: $black;
			position: absolute;
			z-index: 0;
			left: 50%;
			top: 50%;
			transform : translateX(-50%) translateY(-50%);
			z-index: -1;
		}
	}

	a {
		color: $black;
	}
}