/**
 * Addressbook component
 */
.addressbook {
	@include xy-grid-container($padding: 0);
	@include xy-grid;

	select {
		@include breakpoint(medium) {
			@include xy-cell(42%, false);
		}
	}
}