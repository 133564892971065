.modal.modal--search {
	.modal__button {
		margin-top: 0
	}
	
	.modal__products {
		@include xy-grid;
		@include grid-for('.product', 3);
	}

	.modal__searchbutton {
		@include project-negative-button;
		@include flex;
		@include flex-align(center, middle);

		&::after {
			content: "";
			background: url(../../img/icons/chevron.svg) no-repeat;
			width: 7px;
			height: 12px;
			margin-left: 0.5rem;
		}
	}

	.modal__searchform {
		margin-bottom: 2rem;
	}

	.modal__searchtext {
		font-family: $subtitle-font-family;
		font-style: italic;
		font-size: rem-calc(20px);
		margin-bottom: 1.5rem;
		line-height: rem-calc(22px);
		
		&:not(.modal__searchtext--responsive) {
			@include show-for(large);
		}

		&.modal__searchtext--responsive {
			@include hide-for(large);
		}
		
		span {
			font-size: rem-calc(30px);
		}
	}
}