/**
 * Product component
 * Single context
 */
.product.product--single {
	padding-top: 1.8rem;

	@include breakpoint(smedium) {
		padding-top: 3.2rem;
	}
	
	.product__addtocart {
		@include breakpoint(smedium down) {
			margin-top: 1rem;
			margin-bottom: 0.8rem;
		}
	}

	.product__description {
		@include breakpoint(smedium down) {
			margin-top: 1rem;
		}
	}

	.product__discount {
		color: $white;
		position: absolute;
		top: -2rem;
		left: 50%;
		transform: translatex(-50%);
	 	font-weight: 700;
		font-style: rem-calc(16px);
		@include breakpoint(small only) {
			left: calc(100% + 3rem);
			top: 0.5rem;
		}
		
		&::before {
			content: "";
			background: $downy;
			width: 4rem;
			height: 4rem;
			border-radius: 100%;
			display: block;
			position: absolute;
			z-index: -1;
			top: 50%;
			left: 50%;
			transform: translatex(-50%) translateY(-50%);

		}
	}

	.product__flag {
		display: inline-block;

		@include breakpoint(smedium) {
			margin-bottom: 1rem;
		}
	}

	.product__main {
		margin-bottom: 0.2rem;

		@include breakpoint(smedium) {
			margin-bottom: 1.6rem;
		}
	}

	.product__oldprice {
		font-size: rem-calc(17px);
		line-height: rem-calc(20px);
		margin-left: 1rem;
		padding-top: 1rem;
		position: relative;
	}

	.product__price {
		font-size: rem-calc(36px);
		line-height: rem-calc(43px);
	}

	.product__prices {
		margin-bottom: 1.5rem;

		@include breakpoint(smedium) {
			margin-bottom: 2.5rem;
		}
	}
	
	.product__sharebuttons {
		@include breakpoint(small only) {
			margin-bottom: 0.5rem;
		}
	}

	.product__submit {
		@include project-button;
		margin-top: 1.5rem;
	}

	.product__unavailable {
		@include project-subtitle;
		margin-top: 1.5rem;
		color: $alert-color;
	}
}