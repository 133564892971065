/**
 * Homeproducts component
 */
.homeproducts__products {
	@include xy-grid-container($padding: 0);
	@include xy-grid;
	@include grid-for('.product', 3);

	@include breakpoint(smedium down) {
		@include xy-grid-container($padding: 0);
	}
}

.homeproducts__header {
	@include xy-grid-container;
	@include xy-grid;
	@include flex-align(center, middle);
}