.reassurance {
	@include project-sectiontitle;
}

.reassurance__header {
	margin-left: 0;
	margin-right: 0;
	font-size: 1.2rem;
	width: 100%;

	&::before {
		top: 48%;
	}
}

.reassurance__items {
	width: 100%;
}

.reassurance__title {
	padding: 0.8rem 1rem;
}