/**
 * Cartsummary component
 */
.cartsummary {
	@include xy-grid(vertical);
}

.cartsummary__item {
	@include flex;
	@include flex-align(justify, bottom);
}