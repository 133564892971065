/**
 * Homenewsletter component
 * Display by pages: index.html
 * Display by partials: homecontact.html
 */
.homenewsletter form {
	display: flex;
	margin-top: 1rem;
	gap: 5px;

	button {
		background: $white !important;
		color: $heavymetal !important;
		padding: 0 1rem;
		height: 2.57143rem;
		text-transform: uppercase;
		cursor: pointer;

		&:hover {
			transition: background-color 0.25s ease-out, color 0.25s ease-out;
			background: $primary-color;
		}
	}
}

.homenewsletter__info {
	font-family: $subtitle-font-family;
	margin-top: 0.8rem;
	margin-bottom: 1rem;
	color: white;
	font-size: 17px;

	@include breakpoint(large) {
		text-align: right;
	}

	@include breakpoint(small only) {
		font-size: rem-calc(13px);
		margin-top: 0.5rem;
		margin-bottom: 0.9rem;
	}
}

.homenewsletter__title {
	font-family: $title-font-family;
	color: white;
	font-size: rem-calc(20px);
	padding-top: 0.9rem;
	text-transform: uppercase;

	@include breakpoint(large) {
		text-align: right;
	}
}