/**
 * Product component
 * Display by pages: product.html, category.html
 * Display by partials: product.html, product-list.html
 * Contexts:
 * --list: Display product on category page
 * --single: Display product on single page
 * Modifiers :
 * --new: Flag modifier for new products
 * --sale: Flag modifier for sale products
 */
.product {
	color: $body-font-color;
}

.product__brand {
	font-family: $body-font-family;
	font-size: rem-calc(18px);
	line-height: rem-calc(21px);
}

.product__flag {
	background: white;
	padding: 0.1rem 0.5rem;
	font-family: $title-font-family;
	border-top: 2px solid ;
	border-bottom: 2px solid;
	text-transform: uppercase;
	top: 1rem;
	left: 1rem;
	font-size: rem-calc(13px);
	line-height: rem-calc(17px);

	&--new {
		border-color: $jaffa;
	}

	&--sale {
		border-color: $downy;
	}

	&--responsive {
		position: relative;
		top: 0;
		left: 0
	}
}

.product__formfield {
	margin-bottom: 0;
}

.product__image {
	position: relative;

	img {
		width: 100%;
	}
}

.product__label {
	font-family: $subtitle-font-family;
	font-style: italic;

	&.required {
		&::after {
			content: none;
		}
	}
}

.product__oldprice {
	text-decoration: line-through;
}

.product__option {
	margin-bottom: 1rem;
}

.product__price {
	font-family: $title-font-family;
}

.product__title {
	text-transform: uppercase;
	margin-bottom: 0
}
