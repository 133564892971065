.submenu {
	padding-top: 2rem;
		
	ul {
		list-style: none;
		margin: 0;
	}
}

.submenu__button {
	@include project-negative-button;
	margin-top: 1rem;

	&::before {
		content: ">";
		margin-right: 0.5rem;
	}
}

.submenu__level1 {
	&>li  {
		margin-bottom: 3.5rem;

		&>a {
			font-family: $title-font-family;
			text-transform: uppercase;
			color: white;
			font-size: rem-calc(18px);
			line-height: rem-calc(22px);
			display: block;
		}
	}
}

.submenu__level2 {
	li {
		margin: 0;
		margin-right: 15px;

		a {
			padding-left: 15px;
			position: relative;
			color: white;
			line-height: rem-calc(20px);
	
			&::before {
				content: ".";
				line-height: rem-calc(20px);
				position: absolute;
				top: 29%;
				left: 0;
				transform: translateY(-50%);
			}
		}
	}	
}

.submenu__level3 {
	margin: 0 0 0 1rem !important;
 	padding-left: 18px;
 	position: relative;
 	display: none;

	&::before {
		position: absolute;
		content: ">";
		top: 2px;
		left: 0;
	}
}