.checkout.checkout--transporter {
	margin-top: 4rem;
	margin-bottom: 8rem;

	@include breakpoint(medium) {
		margin-top: 10.3rem;
		margin-bottom: 15rem;
	}

	.checkout__submit {
		margin-top: 2rem;

		@include breakpoint(smedium) {
			margin-top: 8rem;
		}
	}
}