body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// Prevent dotted border on focus link and form inputs
input, textarea, select, a { outline: none !important; }

.main {
	position: relative;
	@include breakpoint(smedium down) {
		padding-top: 5rem;
	}
}