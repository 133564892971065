/**
 * Footer component
 * Display by pages: all
 * Display by partials: footer.html
 */
.footer {
	margin-top: 6rem;

	@include breakpoint(small only) {
		margin-top: 3rem;
	}

	&--home {
		margin-top: 0;
	}
}

.footer__address {
	font-family: $subtitle-font-family;
	font-weight: 100;
	font-size: rem-calc(16px);
	line-height: rem-calc(19px);
	
	@include breakpoint(smedium down) {
		margin-top: 0.5rem;
	}
}

.footer__aside {
	@include breakpoint(smedium down) {
		border-top: 1px solid $grey;
		margin-top: 1.8rem;
	}
}

.footer__brands {
	margin-top: 0.5rem;
	line-height: rem-calc(17px);

	@include breakpoint(medium) {
		margin-top: 1rem;
	}

	a {
		color: $body-font-color;
	}
}

.footer__companydomain {
	font-size: rem-calc(16px);
	font-family: $subtitle-font-family;

	@include breakpoint(medium) {
		font-size: rem-calc(17px);

	}
}

.footer__companyname {
	background: url(../../img/logos/santa-rosa-name.svg) no-repeat;
	width: 212px;
	height: 21px;
	margin-top: .5rem;
}

.footer__contactbutton {
	a {
		@include button($background: $body-font-color);
		font-size: rem-calc(16px);
		margin-top: 0.6rem;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}
}

.footer__helptext {
	font-family: $subtitle-font-family;
	font-size: rem-calc(20px);
	margin-top: 1.8rem;
}

.footer__logo {
	background: url('../../img/logos/santa-rosa.svg') no-repeat;
	width: 137px;
	height: 137px;

	@include breakpoint(smedium down) {
		width: 90px;
		height: 90px;
	}
}

.footer__networks {
	a {
		height: 30px;
		width: 30px;
		display: inline-block;
		background: url(../../img/icons/facebook-circle.svg);
		margin-top: 0.6rem;
		
		&:first-child {
			background: url(../../img/icons/facebook-circle.svg);
			margin-right: 0.8rem;
		}


		&:last-child {
			background: url(../../img/icons/instagram-circle.svg);
		}
	}
}

.footer__phone {
	font-family: $title-font-family;
	font-size: rem-calc(23px);
	margin-top: 0.5rem;

	@include breakpoint(smedium down) {
		margin-top: 1.5rem;
	}

	a {
		color: $body-font-color;
	}
}

.footer__wrapper {
	&::before {
		@include line;
		margin-bottom: 4rem;

		@include breakpoint(small only) {
			margin-top: 2rem;
		}
	}
}