/**
 * Homecategories component
 * Display by pages: index.html
 * Display by partials: homecategories.html
 */
.homecategories {
	@include xy-grid-container($padding: 0);
	@include xy-grid;
	@include project-title;
	margin-top: 3rem;

	@include breakpoint(smedium) {
		margin-top: 2rem;
	}

	&::before {
		margin-bottom: 5rem;
	}
}
	
.homecategories__header {
	@include xy-cell(100%);
	text-align: center;
	margin-bottom: 2rem;

	@include breakpoint(small only) {
		@include xy-cell(100%, $gutters: 0);
		margin-bottom: 0.5rem;
	}
}

.homecategories__categories {
	position: relative;

	&--brands {
		.owl-prev {
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
		}

		.owl-next {
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
		}

		&.owl-carousel {
			.owl-item {
				text-align: center;

				img {
					width: 80%;
					display: inline;
				}
			}
		}
	}
}

.homecategories__title {
	&:before {
		@include dotBefore();
	}
}