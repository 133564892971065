/**
 * Form component
 * Display by pages: all
 * Modifiers :
 * &--error: error status for field element
 */
.form__field {
	input[type="checkbox"] {
		margin-bottom: 0;
	}

	label.required {
		&::after {
			content: '*';
			margin-left: 0.5rem;
		}
	}
	
	&.error, .field.error {
		label {
			color: $input-error-color;
		}
		
		input, select {
			border: 1px solid $input-error-color;
			color: $input-error-color;
		}
	}
	
	&--error {
		font-style: italic;
		font-size: 0.9rem;
		color: $input-error-color;
	}
}

.form__line {
    .form__field:nth-child(2) {
        @include breakpoint(smedium) {
            padding-left: 20px;
        }
    }
}

.form__notification {
	font-family: $subtitle-font-family;
	font-style: italic;

	&--error {
		color: $input-error-color;
	}
}

.form__submitbutton {
	@include project-button;
	margin-top: 2rem;

	@include breakpoint (small only) {
		width: 100%;
	}
}

.form___w-submitbutton {
	@include flex;
	@include flex-align(center);
}

.sylius-validation-error {
	font-family: $subtitle-font-family;
	font-style: italic;
	font-size: 0.9rem;
	color: $input-error-color;
	padding-left: 0;
	margin-bottom: 0.5rem;
}