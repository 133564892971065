.homenotice {
  margin-top: 3.4rem;
}

.homenotice__title {
  margin-bottom: 2rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  font-family: $title-font-family;
  font-size: rem-calc(25px);
  line-height: rem-calc(30px);
  text-transform: uppercase;
  text-align: center;
  position: relative;
  display: block;

  &::before {
    content: "";
    height: 2px;
    background: $jaffa;
    width: 30%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  &::after {
    content: "";
    height: 2px;
    background: $jaffa;
    width: 30%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  
  span {
    display: block;
    font-family: $subtitle-font-family;
    font-size: rem-calc(20px);
    font-style: italic;
    text-transform: none;
  }
}
