.modal__aside {
	&--sticky {
		@supports(position: sticky) {
			top: 0;
			position: sticky;
		}
	}
}

.modal__back {
	font-family: $subtitle-font-family;
	font-size: rem-calc(16px);
	color: white;
	cursor: pointer;

	&:hover {
		color: scale-color($white, $lightness: -20%);
	}

	span {
		@include breakpoint (smedium down) {
			display: none
		}
	}

	&::before {
		content: "<";
		margin-right: 0.5rem;
		
		@include breakpoint (small only) {
			font-size: rem-calc(24px);
			content: "\2715";
			margin-right: 0;
		}
	}
}

.modal__button {
	@include project-negative-button;
	width: 100%;
	margin-bottom: 1.5rem;

	&:first-of-type {
		margin-top: 1.5rem;
	}

	&--back {
		background: none;
		border: 1px solid white;
		color: white;

		&:hover {
			background: none;
			color: $grey;
			border-color: white;
		}
	}
}

.modal__content {
	height: 100%;
}

.modal__header {
	margin-bottom: 4rem;

	@include breakpoint(smedium down) {
		margin-bottom: 2rem;
	}
}

.modal__title {
	text-transform: uppercase;
	color: white;
	font-family: $title-font-family;
	font-size: rem-calc(26px);
	line-height: rem-calc(30px);
}