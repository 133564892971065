/**
 * Filters component
 */
.filters {
	@include xy-grid-container;
	@include xy-grid;
	@include flex-align(right, middle);
}

.filters__activefilters {
	display: none;

	&--active {
		@include xy-cell(100%, $gutters: 0);
		@include xy-grid;
		@include flex-align(left, middle);
	}

	a {
		@include flex;
	}
}

.filters__formfield {
	@include flex;
	@include flex-align(justify, middle);

	@include breakpoint(small only) {
		@include xy-cell(100%, $gutters: 0);
	}
}

.filters__wrapper {
	@include xy-cell(100%, $gutters: 0);
	@include xy-grid;
	@include flex-align(justify, middle);
}

.filters__morefilters {
	display: none;
	
	&--active {
		@include xy-cell(100%, $gutters: 0);
		@include xy-grid;
		@include flex-align(left, middle);
	}
	.filters__formfield {
		@include xy-cell(auto);

		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}
	}
}