// Dev file
.maquette {
    position: absolute;
    opacity: 0;
    z-index: 400;
    top: 0px;
    margin: auto;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%,0);
    transform: translate(-50%, 0);
    filter: grayscale(1);
}

.maquette__slider {
    position: fixed;
    z-index: 500;
    top: 10px;
    left: 10px;
    right: 210px;
}

.templates {
    position: fixed;
    right: -240px;
    bottom: 0;
    background: $black;
    padding: 1rem;
    color: white;
    z-index: 200;
    transition-property: right; 
    transition-duration: 0.5s;
    width: 240px;

    span {
        text-transform: uppercase;
        display: block;
        text-align: center;
    }
    
    a {
        display: block;
        color: orange;
    }

    &.active {
        right: 0px;
    }
}

.templates__toggle {
    position: absolute;
    top:0;
    right: 100%;
    padding: 1rem;
    background: $black;
    cursor: pointer;
}