/**
 * Form component
 */
.form__field {
	&.checkbox {
		@include flex;
		@include flex-align(left, middle);
	}
}

.form__line {
	@include flex;

	@include breakpoint(small only) {
		flex-wrap: wrap;
	}

	&>* {
		@include flex-grid-column(6, 0);
		
		@include breakpoint(small only) {
			@include flex-grid-column(12, 0);
		}
	}
}