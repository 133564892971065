// COLORS
$heavymetal: #1D1D1B;
$downy: #5ECEBB;
$grey: #909090;
$jaffa: #F28C47;
$silver: #B2B2B2;
$tundora: #474747;
$tuatara: #2f2f2d;

$border-color: #909090;

$title-font-family:  'novecento_bold', Helvetica, Roboto, Arial, sans-serif;
$subtitle-font-family: 'roboto', Helvetica, Roboto, Arial, sans-serif;