/**
 * topbar component
 * Display by pages: all
 * Display by partials: topbar.html
 */
.topbar {
	font-size: rem-calc(14px);
	padding-top: 1.2rem;
	padding-bottom: 1rem;
	position: fixed;
	top: 0;
	background: white;
	width: 100%;
	z-index: 12;
	transition: padding 0.4s ease-in-out;
	   -moz-transition: padding 0.4s ease-in-out;
	   -webkit-transition: padding 0.4s ease-in-out;

	@include breakpoint(medium) {
		padding-top: 0.7rem;
		padding-bottom: 0.6rem;
	}

	@include breakpoint(smedium down) {
		position: fixed;
		top: 0;
		background: white;
		width: 100%;
		z-index: 10;
	}
	
	&--sticky {
		box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
		padding-top: 1.2rem;
		padding-bottom: 1rem;

		.topbar__menu-secondary {
			display: none !important;
		}

		.topbar__togglemenu {
			opacity: 1;

		}
		.topbar__logo {
			opacity: 1;
		}
	}

	@include breakpoint(small only) {
		padding-bottom: 1.4rem;
	}
}

.topbar__accountbutton {
	color: $body-font-color;
	margin-right: 2.7rem;

	@include breakpoint(small only) {
		margin-right: 0.7rem;
	}

	span {
		@include show-for(medium);
	}

	&::before {
		content: "";
    	width: 15px;
		height: 22px;
    	display: inline-block;
    	background: url(../../img/icons/user.svg) no-repeat;
    	margin-right: 0.2rem;

    	@include breakpoint(small only) {
    		width: 14px;
			height: 21px;
    	}
	}
}

.topbar__cartbutton {
	color: $body-font-color;

	span {
		@include show-for(medium);
	}
	
	&::before {
		content: "";
    	width: 22px;
		height: 22px;
    	display: inline-block;
    	background: url(../../img/icons/cart.svg) no-repeat;
    	margin-right: 0.4rem;

    	@include breakpoint(small only) {
    		width: 21px;
			height: 21px;
    	}
	}

	.topbar__cartbuttoncount {
		width: 2rem;
		height: 2rem;
		line-height: 2rem;
		background: black;
		color: white;
		display: inline-block;
		text-align: center;
		border-radius: 50%;
		margin-left: .75rem;
	}
}

.topbar__logo {
	opacity: 0;
	width: 212px;
	height: 21px;
	display: inline-block;
	background: url(../../img/logos/santa-rosa-name.svg) no-repeat;

	/*transition: opacity 0.4s ease-in-out;
	   -moz-transition: opacity 0.4s ease-in-out;
	   -webkit-transition: opacity 0.4s ease-in-out;*/
	
	@include breakpoint(smedium down) {
		opacity: 1;
	}
}

.topbar__menu-secondary {
	display: none;
	align-items: center;
	gap: 10px;
	font-family: $title-font-family;
	font-size: 17px;
	text-transform: uppercase;

	@include breakpoint(smedium) {
		display: flex;
	}

	a {
		&:before {
			content: '.';
			font-size: 25px;
			line-height: 25px;
			margin-right: 5px;
		}
	}
}

.topbar__searchbutton {
	margin-right: 2.7rem;
	cursor: pointer;
	
	@include breakpoint(small only) {
		margin-right: 0.7rem;
	}

	span {
		@include show-for(medium);
	}

	&::before {
		content: "";
    	width: 20px;
		height: 21px;
    	display: inline-block;
    	background: url(../../img/icons/glass.svg) no-repeat;
    	margin-right: 0.2rem;

    	@include breakpoint(small only) {
    		width: 20px;
			height: 21px;
    	}
	}
}

.topbar__togglemenu{
	opacity: 0;
	width: 34px;
	height: 34px;
	display: inline-block;
	background: url(../../img/icons/toggle-menu.svg) no-repeat;
	margin-right: 0.4rem;
	cursor: pointer;
	/*transition: opacity 0.4s ease-in-out;
	   -moz-transition: opacity 0.4s ease-in-out;
	   -webkit-transition: opacity 0.4s ease-in-out;*/
	
	@include breakpoint(smedium down) {
		opacity: 1;
	}
}