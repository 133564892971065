.modal.modal--search {
	.modal__searchform {
		@include xy-cell(12);

		@include breakpoint(smedium down) {
			@include xy-cell(12, $gutters: 0);
		}
	}

	.modal__title {
		@include xy-cell(12);

		@include breakpoint(smedium down) {
			@include xy-cell(12, $gutters: 0);
		}
	}
}