.menumain.menumain--modal {
	border: none;
	margin-bottom: 1.5rem;

	.menumain__item {
		font-size: rem-calc(18px);
		color: $grey;

		@include breakpoint(smedium only) {
			font-size: 1rem;
			padding: 1rem 0.5rem;
		}

		&--active {
			color: white;
			border-top: 1px solid white;
			border-bottom: 1px solid white;
		}

		&[data-menu-item="blog"] {
			display: none;
		}

		&[data-menu-item="cms/a.propos"] {
			display: none;
		}
	}
}