/**
 * Header component
 * Home context
 */
.header.header--home {
	.header__w-searchform {
		@include xy-grid-container;
		@include xy-grid;
		@include flex-align(center);

		@include breakpoint(smedium down) {
			@include xy-grid-container($padding: 0);
		}
	}

	.header__searchform {
		@include xy-cell(12, $gutters: 0);

		@include breakpoint(medium) {
			@include xy-cell(5);
		}
	}
}