/**
 * Footernav component
 * Display by pages: all
 * Display by partials: footer.html
 */
.footernav {
	background: $body-font-color;
	margin-top: 2rem;

	@include breakpoint(smedium down) {
		padding-top: 0.8rem;
		padding-bottom: 1rem;
		margin-top: 1rem;
	}

	@include breakpoint(medium) {
	}

	a {
		color: white;
		padding: 0.8rem 0.5rem 0.8rem 0;
		font-size: rem-calc(14px);

		@include breakpoint(smedium down) {
			padding-bottom: 0
		}

		&:not(:last-child) {
			&::after {
				@include breakpoint(medium) {
					content: "-";
					margin-left: 0.5rem;
				}
			}
		}
	}
}