/**
 * Product component
 * Single context
 */
.product.product--single {
	@include xy-grid-container;
	@include xy-grid;
	
	.product__addtocart {
		@include breakpoint(smedium) {
			@include xy-cell(auto, $gutters: 84px, $gutter-position: left);
		}
		@include breakpoint(smedium only) {
			@include xy-cell(100%, $gutters: 50px);
			order: 2;
		}

		@include breakpoint(small down) {
			@include xy-cell(100%, $gutters: 0);
			order: 2;
		}
		
		.product__flag {
			@include breakpoint(small only) {
				display: none;
			}
		}
	}

	.product__description {
		@include breakpoint(smedium down) {
			order: 3;
		}

		@include breakpoint(medium) {
			@include xy-cell(36%);
		}
	}
	

	.product__header {
		@include breakpoint(small only) {
			@include xy-cell(100%, $gutters: 0);
			order: 1;
		}
	}

 	.product__main {
		@include xy-cell(100%, $gutters: 0);
		@include xy-grid;
		@include flex-align(justify, top);
	}

	.product__image {
		@include xy-cell(100%, $gutters: 0);
		
		@include breakpoint(smedium) {
			@include xy-cell(45%, $gutter-position: right);
		}

		@include breakpoint(medium) {
			@include xy-cell(31%, $gutter-position: right);
		}
	}
	
	.product__prices {
		@include flex;
		@include flex-align(left, middle);
	}
	
	.product__reassurance  {
		@include xy-cell(100%, $gutters: 0);
	}
	
	.product__sharebuttons {
		@include breakpoint(small only) {
			@include xy-cell(100%, $gutters: 0);
			@include flex-align(justify, middle);
		}

		.product__flag {
			@include breakpoint(smedium) {
				display: none;
			}
		}
	}
}