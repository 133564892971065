.account {
	@include xy-grid-container;
	@include xy-grid;
}

.account__actions {
	@include flex;
	@include flex-align(justify);

	@include breakpoint(smedium down) {
		@include flex-direction(column);
	}

	a {
		@include breakpoint(smedium down) {
			order: 2
		}
	}
}

.account__addresses {
	@include xy-cell(12, $gutters: 0);
	@include flex;
	flex-wrap: wrap;
	@include flex-align(left, stretch);

	.address {
		@include xy-cell(12, $gutters: 0);
		
		@include breakpoint(medium) {
			@include xy-cell(4, $gutters: 40px, $gutter-position: right);
		}
	}
}

.account__column {
	&:first-child {
		@include xy-cell(6, $gutters: 40px, $gutter-position: right);

		@include breakpoint(small only) {
			@include xy-cell(12, $gutters: 0);
		}
	}

	&:last-child {
		@include xy-cell(6, $gutters: 40px, $gutter-position: left);

		@include breakpoint(small only) {
			@include xy-cell(12, $gutters: 0);
		}
	}
	
	&--vertical {
		@include flex;
		@include flex-align(left, middle);
		@include flex-direction(column);
	}

}

.account__main {
	@include xy-cell(8, $gutters: 0);

	@include breakpoint(smedium down) {
		@include xy-cell(12, $gutters: 0);
	}

	&--full {
		@include xy-cell(12, $gutters: 0);
	}
}

.account__menu {
	@include xy-cell(12, $gutters: 0);
	@include flex;
	@include flex-align(center, middle);
	flex-wrap: wrap;
}

.account__menumobile {
	@include flex;
	@include flex-direction(column)

}

.account__menumobileitem {
	flex: 1 1 auto; 
}

.account__previous {
	@include xy-cell(6, $gutters: 10px, $gutter-position: right);
	
	@include breakpoint(small only) {
		@include xy-cell(12, $gutters: 0);
	}
}

.account__submit {
	@include xy-grid;
	@include flex-align(justify, middle);
}

.account__submitbutton {
	@include xy-cell(6, $gutters: 10px, $gutter-position: left);

	@include breakpoint(small only) {
		@include xy-cell(12, $gutters: 0);
	}
}

.account__w-column {
	@include xy-cell(12, $gutters: 0);
	@include flex;

	@include breakpoint(smedium down) {
		flex-wrap: wrap;
	}
}