/**
 * Homepage component
 * Display by pages: index.html
 */

.homepage {
  @include xy-grid-container();
  @include xy-grid;

  &__heading {
    grid-area: heading;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    min-height: 200px;
  }

  &__heading-title {
    font-family: $title-font-family;
    font-size: rem-calc(30px);
    text-align: center;
  }

  &__heading-dot {
    @include dot;
  }

  &__heading-title-alt {
    font-family: $subtitle-font-family;
    font-size: rem-calc(18px);
    text-align: center;
  }

  &__push {
    grid-area: push;
  }

  &__taxon {
    &--category_parfums {
      grid-area: taxon-main;
    }

    &--category_cosmetiques {
      grid-area: taxon-2;
    }

    &--category_maquillage {
      grid-area: taxon-1;
    }

    &--category_petits_plus {
      grid-area: taxon-3;
    }
    &--category_ambiance {
      grid-area: taxon-4;
    }
  }

  &__video {
    grid-area: video;
  }

  &__wrapper {
    @include xy-cell(full, $gutters: 0);

    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    grid-template-areas:
      "video"
      "heading"
      "taxon-main"
      "taxon-1"
      "taxon-2"
      "taxon-3"
      "push"
      "taxon-4";

    @include breakpoint(large) {
      @include xy-cell(full);

      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: min-content;
      grid-template-areas:
      "video video video"
      "heading taxon-main taxon-main"
      "taxon-1 taxon-2 taxon-3"
      "push push taxon-4";
    }
  }
}