/**
 * Checkout component
 * &--connection : connection checkout step
 */
.checkout.checkout--connection {
	@include flex-align(justify);

	.checkout__main {
		@include xy-cell(12, $gutters: 0);
		
		@include breakpoint(medium) {
			@include xy-cell(5, $gutter-position: right);
		}

		&:last-child {
			@include breakpoint(medium) {
				@include xy-cell(5, $gutter-position: left);
				margin-right: 0;
			}
		}
	}

	.checkout__submit {
		&--centered {
			@include flex-align(center);
		}
	}
}