/**
 * Homenewsletter component
 */
.homenewsletter {
	@include xy-cell(100%, $gutters: 0);
	@include xy-grid;
}

.homenewsletter__form {
	@include xy-cell(12);
	@include flex;
	align-items: center;
	flex-direction: column;

	@include breakpoint(large) {
		@include xy-cell(6, $gutters: 0);
		flex-direction: row;
	}
}

.homenewsletter__heading {
	@include xy-cell(12);

	@include breakpoint(large) {
		@include xy-cell(6);
	}
}
