.button {
	@include project-button;

	&--negative {
		@include project-negative-button;
	}
}

label {
	&.required {
		&::after {
			content: '*';
			display: inline-block;
            padding-left: 0.5rem;
            size: 0.8rem;
		}
	}
}

#sylius-payment-method-instructions {
	table {
		margin: 20px 0;
	}

	th, td {
		text-align: left;
		padding-right: 20px;
	}

	@media only screen and (max-width: 800px) {

		 table { width: 100%; border-collapse: collapse; border-spacing: 0; }

		 th,
		 td { margin: 0; vertical-align: top; }
		 th { text-align: left; }

		 table { display: block; position: relative; width: 100%; }
		 thead { display: block; float: left; }
		 tbody { display: block; width: auto; position: relative; overflow-x: auto; white-space: nowrap; }
		 thead tr { display: block; }
		 th { display: block; text-align: right; }
		 tbody tr { display: inline-block; vertical-align: top; }
		 td { display: block; min-height: 1.25em; text-align: left; }
	}
}