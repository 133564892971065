.searchformmodal {
	@include flex;
	
	input {
		@include xy-cell(6, $gutter-position: right);
	}
	
	button {
		@include xy-cell(3, $gutter-position: right);
	}

	a {
		@include xy-cell(4, $gutter-position: left);
		margin-left: auto;
	}
}