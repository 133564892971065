/**
 * Checkout component
 * &--complete : complete checkout step
 */
.checkout.checkout--complete {
	.checkout__submit {
		@include flex-align(center, middle);
	}

	.checkout__submitbutton {
		@include breakpoint(medium) {
			@include xy-cell(4, $gutter-position: left);
		}

		&--large {
			@include breakpoint(medium) {
				@include xy-cell(8, $gutter-position: left);
			}
		}
	}
}