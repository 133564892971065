/**
 * Homecontact component
 * Display by pages: index.html
 * Display by partials: homecontact.html
 */
.homecontact {
	background: $heavymetal;
  	margin-top: 2rem;
	margin-bottom: -1px;
	padding-top: 2rem;
	padding-bottom: 2rem;

	@include breakpoint(smedium up) {
		margin-top: 4rem;
	}
}

.homecontact__newsletter {
	@include xy-cell(100%);
	@include xy-grid;
	@include flex-align(right);

	@include breakpoint(smedium down) {
		@include xy-cell(12, $gutters: 0);
	}

	@include breakpoint(smedium down) {
		margin-bottom: 1rem;
	}
}

.homecontact__wrapper {
	@include xy-grid-container($padding: 0);
	@include xy-grid;
}
