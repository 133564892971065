/**
 * Display elements as grid
 * @param  {[type]} $selector class elements to display as grid
 */
@mixin grid-for($selector, $nb: 4) {
	#{$selector} {
		@include breakpoint(smedium) {
			@include xy-cell(1 of $nb, $gutters: 10px);
		}

		@include breakpoint(medium) {
			@include xy-cell(1 of $nb);
		}

		&:nth-child(2n+1) {
			@include breakpoint(small only) {
				@include xy-cell(1 of 2,$gutter-type: padding, $gutters: 5px, $gutter-position: right);
				#{$selector}__main {
					padding-left: 1rem;
				}

			}
		}

		&:nth-child(2n) {
			@include breakpoint(small only) {
				@include xy-cell(1 of 2,$gutter-type: padding, $gutters: 5px, $gutter-position: left);
			}
		}

		img {
			width: 100%;
		}
	}
}

/**
 * Project button
 * Dark button
 */
@mixin project-button(
	$background: $heavymetal,
	$background-hover: $button-background-hover-lightness,
	$color: white
) {
	@include button;
	@include button-style(
		$background: $background,
		$background-hover: $background-hover,
		$color: $color
	);
	padding-left: 3.6rem;
	padding-right: 3.6rem;
}


/**
 * Negative project button
 * borderred button
 */
@mixin project-negative-button() {
	@include button($background: $white, $color: $body-font-color);
	padding-left: 3.6rem;
	padding-right: 3.6rem;
	border: none;

	&:hover {
		background: white;
		color: $tundora;
		border-color: $tundora;
	}
}

/**
 * Bordered project button
 * borderred button
 */
@mixin project-border-button($color: $body-font-color) {
	@include button($color: $color);
	background: none;
	padding-left: 3.6rem;
	padding-right: 3.6rem;
	border: 1px solid $border-color;

	&:hover {
		background: white;
		color: $tundora;
		border-color: $border-color;
	}
}

/**
 * Project title features
 */
@mixin project-title($font-size: 24px, $line-height: 28px) {
	text-transform: uppercase;
	font-family: $title-font-family;
	font-size: rem-calc($font-size);
	line-height: rem-calc($line-height);
}

@mixin project-subtitle($font-size: 20px, $line-height: 24px) {
	font-family: $subtitle-font-family;
	font-size: rem-calc($font-size);
	line-height: rem-calc($line-height);
}

/**
 * Project section title
 */
@mixin project-sectiontitle {
	@include xy-grid-container($padding: 0);
	@include xy-grid;

	#{&}__header {
		@include xy-cell(100%);

		@include breakpoint(small only) {
			@include xy-cell(100%, $gutters: 0);
		}

		text-transform: uppercase;
		font-family: $title-font-family;
		font-size: rem-calc(24px);
		line-height: rem-calc(30px);
		text-align: center;
		position: relative;
		background: white;
		margin-top: 0.5rem;
		margin-bottom: 0.8rem;

		@include breakpoint(smedium) {
			margin-top: 2rem;
			margin-bottom: 2.5rem;
			font-size: rem-calc(25px);
		}
	}

	#{&}__title {
		background: white;
		display: inline-block;
		position: relative;
		z-index: 3;
		padding: 1rem 0.5rem;

		@include breakpoint(smedium) {
			padding: 1rem 1.5rem;
		}
	}
}

/**
 * Show/hide element for given breakpoint
 * @param  {[type]} $selector    class element to show/hide
 * @param  {[type]} $breakpoint: smedium       breakpoint
 */
@mixin responsive-display($selector, $breakpoint: smedium) {
	&:not(#{$selector}--responsive) {
		@include show-for($breakpoint);
	}

	#{$selector}--responsive {
		@include hide-for($breakpoint);
	}
}

@mixin dot($size: 25px) {
	width: $size;
	height: $size;
	background: $heavymetal ;
	border-radius: 50%;
}

@mixin dotBefore($size: 32px) {
	content: ".";
	font-size: $size;
	line-height: $size;
	margin-right: 5px;
}


@mixin line {
	content: "";
	background: url(../img/backgrounds/ligne.svg) no-repeat center center;
	width: 100%;
	height: 16px;
	background-size: cover;

	@include breakpoint (smedium down) {
		background-size: cover;
	}
}