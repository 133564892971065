/**
 * Category component
 * Display by pages: category.html
 * Display by partials: category.html
 */
.category__header {
	margin-top: 3.2rem;
	margin-bottom: 0.2rem;

	@include breakpoint(smedium) {
		margin-bottom: 1.6rem;
	}
}

.category__image {
	@include breakpoint(smedium down) {
		margin-bottom: 1.5rem;
	}
	
	img {
		width: 100%;
	}
}

.category__products {
	margin-top: 2.8rem;

	&--empty {
		color: $jaffa;
	}
}

.category__title {
	text-transform: uppercase;
}