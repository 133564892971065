.brand {
	@include xy-grid-container;
}

.brand__wrapper {
	@include xy-grid;
}

.brand__logo {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.brand__header {
	@include xy-cell(100%, $gutters: 0);
	@include flex;
	@include flex-align(justify);
}

.brand__image {
	@include breakpoint(small only) {
		@include xy-cell(100%, $gutters: 0);
	}

	@include breakpoint(smedium only) {
		@include xy-cell(50%, $gutters: 0);
	}

	@include breakpoint(medium up) {
		@include xy-cell(35%, $gutters: 0);

	}
}

.brand__main {
	@include breakpoint(smedium) {
		@include xy-cell(auto, $gutters: 60px, $gutter-position: left);
	}

	&--withoutimage {
		@include breakpoint(smedium) {
			@include xy-cell(auto, $gutters: 0);
		}
	}
}

.brand__w-button {
	@include flex;
	@include flex-align(center);
}
