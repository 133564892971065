/**
 * Transporter component
 */

.transporter {
	@include xy-grid;
	@include flex-align(left, top);
}

.transporter__description {
	@include xy-cell(9, $gutter-position: right);

	@include breakpoint(small only) {
		@include xy-cell(8, $gutter-position: right);
	}
}

.transporter__price {
	@include xy-cell(2, $gutter-position: left);
}

.transporter__radio {
	@include xy-cell(1, $gutter-position: right);
	
	@include breakpoint(small only) {
		@include xy-cell(2, $gutter-position: right);
	}
}