.popin {
  position: fixed;
  max-width: 550px;
  width: 90%;
  background-color: white;
  border: $black 1px solid;
  padding: 2rem;
  z-index: 9999;

  bottom: -100%;
  left: 50%;
  transform: translateX(-50%);

  transition: bottom 1s;

  &--show {
    bottom: 150px;
    transition: bottom 1s;
  }
}

.popin__close {
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  font-size: 2rem;
  width: 20px;
  height: 20px;
  cursor: pointer;
  transform: rotate(45deg);
}

.popin__description {
  font-family: $title-font-family;
  display: block;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

.popin__form {
  button {
    margin: 0.5rem auto 0;
    display: block;
  }
}

.popin__image {
  display: flex;
  justify-content: center;
  background: url('../../img/logos/santa-rosa-yellow.svg') no-repeat;
  width: 150px;
  height: 150px;
  margin: 0 auto 1rem;
}
