.addtocart {
	@include xy-grid;
}

.addtocart__image {
	@include xy-cell(4, $gutter-position: right);
	@include xy-grid;
	@include flex-align(left);
	
	@include breakpoint(small only) {
		@include xy-cell(6, $gutter-position: right);
	}
}

.addtocart__content {
	@include xy-grid(vertical);
	@include xy-cell(8);
	
	@include breakpoint(large) {
		@include xy-cell(8);
	}

	@include breakpoint(small only) {
		@include xy-cell(6, $gutters: 0);
	}
}