/**
 * Header component
 * Display by pages: all
 * Display by partials: header.html
 * Context:
 * &--home
 */
.header__navigation {
	@include xy-grid-container;
	@include xy-grid;
}

.header__wrapper {
	@include xy-grid-container;
	@include xy-grid;
	@include flex-align(center);
}