.addtocart__image {
	img {
		width: 100%
	}
}

.addtocart__productname {
	span {
		display: block;
		@include project-title(20px, 24px);
	}
}

.addtocart__price {
	margin-top: 1rem;

	span {
		font-family: $title-font-family;
		font-weight: bold;
	}
}

.addtocart__quantity {
	margin-top: auto;

	span {
		font-family: $title-font-family;
		font-weight: bold;
	}
}

.add_to_cart_button {
	cursor: pointer;
}