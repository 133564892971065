/**
 * Step component
 */
.step {
	@include flex;
	@include flex-align(justify, middle);
	@include show-for(smedium);
}

.step__chevron {
	@include show-for(smedium);
}