/**
 * Filters component
 * Display by pages: category.html
 * Display by partials: filters.html
 */
.filters__activefilters {
	margin-top: 2rem;

	label {
		@include project-subtitle(14px, 16px);
		margin-right: 1rem;
	}

	a {
		background: $body-font-color;
		color: white;
		padding: 0.3rem 4rem 0.3rem 0.5rem;
		margin-right: 1rem;
		position: relative;

		&::after {
			content:"";
			background: url(../../img/icons/close.svg) no-repeat;
			width: 12px;
			height: 12px;
			position: absolute;
			top: 50%;
			right: 0.5rem;
			transform: translateY(-50%);
		}
	}
}

.filters__displaymore {
	color: $black;
	text-decoration: underline;
	cursor: pointer;
}

.filters__formfield {
	input, select {
		margin-bottom: 0
	}

	label {
		font-family: $subtitle-font-family;
		font-style: italic;
		margin-right: 1rem;
	}

	&--alignleft {
		margin-right: auto;
	}

	&--paddingright {
		@include breakpoint(smedium) {
			margin-right: 2rem;
		}
	}

	@include breakpoint(smedium down) {
		margin-bottom: 1rem;
	}

	&--keyword {
		@include breakpoint(smedium) {
			margin-right: 1rem;
			width: 23rem;
		}
	}
}

.filters__morefilters {
	margin-top: 1rem;

	.fakeselect {
		min-width: 250px;
	}

	.fakeselect:not(:last-child) {
		margin-right: 1.5rem;
	}
}

.filters__wrapper {
	padding-top: 0.8rem;
	padding-bottom: 0.7rem;
	border-top: 1px solid $border-color;
	border-bottom: 1px solid $border-color;
	margin-top: 2.5rem;
}
