.cookiebanner {
	background: $black;
	color: white;
	display: none;
	position: fixed;
	bottom: 0;
	width: 100%;
	padding-top: 1rem;
	padding-bottom: 1rem;
	z-index: 100;

  &.active {
    display: block;
  }
}

.cookiebanner__button {
	@include project-border-button($color: white);

	margin-top: 1rem;
	margin-bottom: 0;
}

// Hack for hidding cookie modal content when JS is out of service
#cookie-inform-and-ask {
	display: none;
}

.tingle-enabled {
	#cookie-inform-and-ask {
		display: block;
	}
}