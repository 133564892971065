.menumobile {
	&,ul {
		list-style: none;
		margin: 0;
	}
	
	a {
		background: white;
		padding: 25px 20px;
		font-family: $title-font-family;
		font-size: rem-calc(25px);
		line-height: rem-calc(30px);
		border-bottom: 1px solid $border-color;

		&.active {
			span {
				&::after {
					background: url('../../img/icons/chevron-white.svg') no-repeat;
					transform: rotate(-90deg);
				}
			}
		}

		&.childfree {
			&::after {
				content:none !important;
			}
		}

		span {
			width: 35px;
			height: 35px;

			&::after {
				content:'';
				background: url('../../img/icons/chevron.svg') no-repeat;
				width: 16px;
				height: 29px;
				transform: rotate(90deg);

			}
		}
	}

	&>li>a.active {
		background: $heavymetal;
		color: white;
	}

	ul {
		max-height: 0;
    	opacity: 0;
    	transition: all .5s ease-in-out;

	    &.active {
    		max-height: 3500px;
	    	opacity: 1;
	    }

		a {
			@include project-subtitle(18px, 21px);
			background: $tuatara;
			color: white;
				
			span {
				&::after {
					background: url('../../img/icons/chevron-white.svg') no-repeat;
				}
			}
		}

		ul {
			a {
				font-family: $body-font-family;
				font-size: rem-calc(14px);
				line-height: rem-calc(16px);
				font-style: inherit;
				position: relative;
				padding: 25px 20px 25px 30px;

				&::before {
					content: ".";
					line-height: rem-calc(20px);
					position: absolute;
					top: 45%;
					left: 20px;
					transform: translateY(-50%);
				}
			}
		}
	}
}