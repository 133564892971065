/**
 * Homecategory component
 * Display by pages: index.html
 * Display by partials: homecategories.html
 */
.homecategory {
	position: relative;
	display: block;
	overflow: hidden;
	height: 100%;

	&:hover {
		img {
			filter: blur(4px);
		}

		.homecategory__title {
			visibility: inherit;
		}

		&:after {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, 0.5);
			top: 0;
			left: 0;

		}
	}
}

.homecategory__image {
	width: 100%;
	height: 100%;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;

		@include breakpoint(large) {
			max-width: 328px;
		}
	}

	&--main {
		img {
			min-height: 250px;
			max-width: 100%;
		}
	}
}

.homecategory__title {
	position: absolute;
	z-index: 5;
	bottom: 2rem;
	right: 2rem;
	font-family: $title-font-family;
	font-size: rem-calc(24px);
	color: white;
	text-align: center;
	margin-bottom: 0;

	@include breakpoint(medium) {
		visibility: hidden;
	}
}
