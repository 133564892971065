/**
 * Product component
 * List context
 */
.product.product--list {
	margin-bottom: 2.1rem;

	.product__detail {
		@include project-button;
		margin: .4rem;
		margin-top: 1rem;
		width: 80%;
		text-align: center;
		padding: 0.85rem 1rem;
	}

	.product__flag {
		position: absolute;
	}

	.product__image {
		position: relative;
	}

	.product__main {
		color: $body-font-color;
	}

	.product__oldprice {
		font-size: rem-calc(14px);
	}

	.product__price {
		font-size: rem-calc(16px);

		span {
			display: block;
		}

		&--bigger {
			font-size: rem-calc(20px);
		}

		@include breakpoint(smedium) {
			margin-top: .4rem;
			text-align: right;
		}
	}

	.product__rollover {
		background: rgba(255, 255, 255, 0.9);
		border: 1px solid $silver;
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
	}

	.product__submit {
		border: 1px solid $body-font-color;
		width: 80%;
		text-align: center;
		font-family: $subtitle-font-family;
		color: $body-font-color;
		padding: 0.65rem 1rem;
	}

	.product__subtitle {
		font-size: rem-calc(14px);
		font-family: $body-font-family;

		&--bigger {
			font-size: rem-calc(16px);
		}

		@include breakpoint(small only) {
			margin: 0;
		}
	}

	.product__title {
		font-family: $title-font-family;
		font-size: rem-calc(16px);
		margin-top: .5rem;

		&--bigger {
			font-size: rem-calc(20px);
		}

		@include breakpoint(small only) {
			margin-top: 0.65rem;
		}
	}
}
