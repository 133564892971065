/**
 * Checkout component
 */
.checkout {
	@include xy-grid-container;
	@include xy-grid;
}

.checkout__address {
	@include xy-grid;

	.address:first-child {
		@include xy-cell(12, $gutters: 0);
		
		@include breakpoint(smedium) {
			@include xy-cell(auto, $gutters: 70px, $gutter-position: right);
		}
	}

	.address:last-child {
		@include xy-cell(12, $gutters: 0);
		
		@include breakpoint(smedium) {
			@include xy-cell(auto, $gutters: 70px, $gutter-position: left);
		}
	}
}

.checkout__aside {
	@include xy-cell(12, $gutters: 0);
	
	@include breakpoint(medium) {
		@include xy-cell(auto, $gutters: 80px, $gutter-position: left);
	}
}

.checkout__cartsummary {
	@include xy-grid;
	@include flex-align(right);

	.cartsummary {
		@include xy-cell(4, $gutters: 0);
	}

	.cartsummary--complete {
		@include xy-cell(12, $gutters: 0);
		
		@include breakpoint(medium) {
			@include xy-cell(5, $gutters: 0);
		}
	}
}

.checkout__changepayment {
	@include xy-cell(12, $gutters: 0);
}

.checkout__main {
	@include xy-cell(12, $gutters: 0);
	
	@include breakpoint(medium) {
		&:not(&--full) {
			@include xy-cell(8, $gutter-position: right);
		}
	}
}

.checkout__previous {
	@include xy-cell(6, $gutters: 10px, $gutter-position: right);
	
	@include breakpoint(small only) {
		@include xy-cell(12, $gutters: 0);
	}
}

.checkout__resume {
	@include xy-grid;
	@include flex-align(center, middle);

	&:first-child {
		@include xy-cell(12, $gutters: 0);
		
		@include breakpoint(smedium) {
			@include xy-cell(6, $gutter-position: right);
		}
	}

	&:last-child {
		@include xy-cell(12, $gutters: 0);
		
		@include breakpoint(smedium) {
			@include xy-cell(6, $gutter-position: left);
		}
	}
}

.checkout__resumelabel {
	@include xy-grid;
	@include flex-align(center, middle);
}

.checkout__submit {
	@include xy-grid;
	@include flex-align(justify, middle);
	

	&--complete {
		@include flex-align(center, middle);
	}

	&--responsive {
		@include hide-for(smedium);
	}
}

.checkout__submitbutton {
	@include xy-cell(6, $gutters: 10px, $gutter-position: left);

	@include breakpoint(small only) {
		@include xy-cell(12, $gutters: 0);
	}
}

.checkout__subtitle {
	@include xy-cell(12, $gutters: 0);
}

.checkout__w-resume {
	@include xy-grid;
	@include flex-align(justify);
}