/**
 * Searchform component
 * Display by pages: index.html
 * Display by partials: header.html
 */
.searchform {
	position: relative;
	
	input {
		margin-bottom: 0;
	}

	input::placeholder {
		font-style: italic;
	}

	.field {
		width: 100%;
	}
}

.searchform__button {
	background: url('../../img/icons/glass.svg') no-repeat;
	width: 19px;
	height: 20px;
	position: absolute;
	right: 0.5rem;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
}