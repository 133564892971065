.menumobile {
	@include hide-for(medium);
	
	li {
		@include flex;
		@include flex-direction(column);
		
		a {
			flex: 1;
			@include flex;
			@include flex-align(justify, middle);

			span {
				@include flex;
				@include flex-align(center, middle);
			}
		}
	}
}