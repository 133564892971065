/**
 * Payment component
 */
.payment {
	margin-bottom: 4rem;

	@include breakpoint(small only) {
		margin-bottom: 2rem;
	}

	label.required::after {
		content: none;
	}
}

.payment__icon {
	label {
		display: block;
		width: 83px;
		
		@include breakpoint(small only) {
			width: 40px;
		}
	}

	&--creditcard, &--systempay {
		label {
			background: url('../../img/icons/creditcard.svg') no-repeat;
			height: 83px;

			@include breakpoint(small only) {
				height: 40px;
			}
		}
	}

	&--cheque {
		label {
			background: url('../../img/icons/cheque.svg') no-repeat;
			height: 70px;

			@include breakpoint(small only) {
				height: 33px;
			}
		}
	}

	&--cash_on_delivery {
		label {
			background: url('../../img/icons/store.svg') no-repeat;
			height: 70px;

			@include breakpoint(small only) {
				height: 33px;
			}
		}
	}
}

.payment__name {
	label {
		@include project-title;

		@include breakpoint(small only) {
			@include project-title(16px, 18px);
		}
	}
}
