.orderlabel {
	background: $downy;
	color: white;
	font-family: $title-font-family;
	font-size: rem-calc(15px);
	padding: 0.3rem; 

	&--new {
		background: $jaffa;
	}

	&--cancelled {
		background: $tundora;
	}
}