/**
 * Homepush component
 * Display by pages: index.html
 * Display by partials: homepush.html
 */
.homepush {
	@include xy-grid-container($padding: 0);
	@include xy-grid;

	@include breakpoint(smedium down) {
		@include xy-grid-container($padding: 0);
	}

	background: #DAE8D8;
	position: relative;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 360px;
}

.homepush__button {
	@include project-button;
	text-transform: uppercase;
	font-family: $title-font-family;
	font-style: normal;
	font-size: rem-calc(14px);
	padding: 0.6rem 2rem;
}

.homepush__content {
	z-index: 9;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 20px;
	background: white;
	padding: 2rem;
	max-height: 85%;
	max-width: 85%;
}

.homepush__image {
	position: absolute;
	z-index: 1;
	height: 100%;
	width: 100%;

	@include xy-cell(100%, $gutters: 0);

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.homepush__title {
	font-family: $title-font-family;
	font-size: rem-calc(25px);
	line-height: rem-calc(30px);
	text-transform: uppercase;
	text-align: center;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;

	@include breakpoint(smedium) {
		font-size: rem-calc(18px);
		line-height: rem-calc(20px);
	}
	
	span {
		display: block;
		font-family: $subtitle-font-family;
		font-size: rem-calc(14px);
		line-height: rem-calc(18px);
		text-transform: none;

		@include breakpoint(smedium) {
			font-size: rem-calc(20px);
			line-height: rem-calc(22px);
		}
	}
}

.homepush__wrapper {
}