/**
 * Payment component
 */

.payment {
	@include xy-grid;
	@include flex-align(left, top);
}

.payment__description {
	@include xy-cell(9, $gutter-position: right);
	
	@include breakpoint(small only) {
		@include xy-cell(8, $gutter-position: right);
	}
}

.payment__icon {
	@include xy-cell(2, $gutter-position: left);
}

.payment__radio {
	@include xy-cell(1, $gutter-position: right);

	@include breakpoint(small only) {
		@include xy-cell(2, $gutter-position: right);
	}
}