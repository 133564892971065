/**
 * Homereassurance component
 */
.homereassurance__items {
	@include xy-cell(100%);
	@include xy-grid;
	@include flex-align(spaced);

	&>* {
		@include breakpoint(small only) {
			@include xy-cell(100%);
		}
	}
}