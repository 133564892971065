.modal.modal--notification {
	.modal__header {
		@include flex-direction(column);
		&::before {
		}
	}

	.modal__title {
		@include xy-cell(12);
	}
}