/**
 * Transporter comonent
 */
.transporter {
	margin-bottom: 4rem;
	
	@include breakpoint(small only) {
		margin-bottom: 2rem;
	}

	label.required::after {
		content: none;
	}
}

.transporter__name {
	label {
		@include project-title;

		@include breakpoint(small only) {
			@include project-title(16px, 18px);
		}
	}
}

.transporter__price {
	@include project-title;
	text-align: right;

	@include breakpoint(small only) {
		@include project-title(16px, 18px);
	}
}

select {
	background-image: url(../../img/icons/select.svg);
	background-size: 24px 37px;
	background-position: right -3em top -0.57rem;
	padding-right: 3rem;
}