/**
 * Product component
 * List context
 */
.product.product--list {

	.product__header {
		@include xy-cell(100%, false);
		
		@include breakpoint(smedium) {
			@include xy-cell(70%, false);
		}
	}
	
	.product__image {
		@include xy-cell(100%, $gutters: 0);
	}

	.product__main {
		@include xy-cell(100%, $gutters: 0);
		@include xy-grid;
		@include flex-align(justify, top);
	}

	.product__prices {
		@include xy-cell(100%, false);
		
		@include breakpoint(smedium) {
			@include xy-cell(30%, false);
		}
	}

	.product__rollover {
		display: none;
		@include flex-align(center, middle);
	}

	&.active {
		.product__rollover {
			@include xy-grid(vertical);
			@include show-for(medium);
		}
	}
}