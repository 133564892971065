/**
 * Footer component
 * Display by pages: all
 * Display by partials: footer.html
 */
.footer.footer--home {
	.footer__wrapper {
		margin-top: 4rem;

		@include breakpoint(small only) {
			margin-top: 2rem;
		}

		&::before {
			content: none;
		}
	}
}