/**
 * Header component
 * Display by pages: all
 * Display by partials: header.html
 * Context:
 * &--home
 */
.header {
	padding-top: 3.8rem;
}

.header:not(.header--home) {
	@include show-for(medium);
}

.header__title {
	display: none;
}

.header__wrapper {
	padding-top: 0.5rem;
	margin-bottom: 1.5rem;

	a {
		background: url('../../img/logos/santa-rosa.svg') no-repeat;
		width: 157px;
		height: 157px;
	}
} 