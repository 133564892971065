/**
 * Cartitem component
 */
.cartitem {
	@include xy-grid;
	
	@include breakpoint(medium) {
		@include flex-align(left, middle);
	}

	&--heading {
		@include show-for(medium);
	}
}

.cartitem__amount {
	@include xy-cell(100%, $gutters: 0);

	@include breakpoint(medium) {
		@include xy-cell(20%, $gutter-position: right);
	}

	&:not(&--responsive) {
		@include show-for(medium);
	}

	&--responsive {
		@include hide-for(medium);
	}
}

.cartitem__delete {
	@include xy-cell(auto, $gutters: 0);
}

.cartitem__image {
	@include xy-cell(40%, $gutter-position: right);
	
	@include breakpoint(medium) {
		@include xy-cell(16%, $gutters: 0);
	}
}

.cartitem__name {
	@include xy-cell(60%, $gutter-position: right);

	@include breakpoint(medium) {
		@include xy-cell(30%, $gutter-position: right);
	}
}

.cartitem__price {
	@include xy-cell(100%, $gutters: 0);

	@include breakpoint(medium) {
		@include xy-cell(20%, $gutter-position: right);
	}
	
	&:not(&--responsive) {
		@include show-for(medium);
	}

	&--responsive {
		@include hide-for(medium);
	}
}

.cartitem__quantity {
	@include xy-cell(100%, $gutters: 0);

	@include breakpoint(medium) {
		@include xy-cell(20%, $gutter-position: right);
	}

	span {
		@include hide-for(medium);
	}

	&:not(&--responsive) {
		@include show-for(medium);
	}

	&--responsive {
		@include hide-for(medium);
	}
}

.cartitem.cartitem--heading {
	.cartitem__name {
		@include breakpoint(medium) {
			@include xy-cell(45%, $gutter-position: right);
		}
	}

	&.cartitem--full {
		.cartitem__name {
			@include breakpoint(medium) {
				@include xy-cell(50%, $gutter-position: right);
			}
		}
	}
}

.cartitem.cartitem--full {
	.cartitem__image {
		@include breakpoint(medium) {
			@include xy-cell(10%, $gutters: 0);
		}
	}

	.cartitem__name {
		@include breakpoint(medium) {
			@include xy-cell(40%, $gutter-position: right);
		}
	}

	.cartitem__quantity {
		@include breakpoint(medium) {
			@include xy-cell(15%, $gutter-position: right);
		}
	}
}