.fakeselect {
	position: relative;
	cursor: pointer;
	
	&--sorting {
		min-width: 180px;
	}

	&--active {
		.fakeselect__menu {
			display: block;
		}
	}

	.menu {
		border: none;
	}
}

.fakeselect__menu {
	position: absolute;
	z-index: 10;
	border-top: 1px solid $medium-gray;
	border-right: 1px solid $medium-gray;
	border-left: 1px solid $medium-gray;
	border-bottom: 1px solid $medium-gray;
	width: 100%;
	padding: 5px 10px;
	background: white;
	display: none;
	top: 100%;
}

.fakeselect__menuitem {
	display: block;
	text-decoration: none !important;
	
	&--disable {
		color: $grey;
		pointer-events: none;
  		cursor: none;
	}

	&:not(:first-child) {
		margin-top: 5px;
	}
}

.fakeselect__current {
	@include flex;
	@include flex-align(justify, middle);
	border: 1px solid $medium-gray;
	padding-left: 10px;

	&::after {
		content: "";
		background: url(../../img/icons/select.svg) no-repeat;
		width: 24px;
		height: 37px;
		margin-left: 10px;
	}
}