@font-face {
  font-family: 'novecento_bold';
  src: url('../fonts/novencentosanswide/novecentosanswide-bold.woff2') format('woff2'),
  url('../fonts/novencentosanswide/novecentosanswide-bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'helvetica_light';
  src: url('../fonts/helvetica/helvetica-light.woff2') format('woff2'),
      url('../fonts/helvetica/helvetica-light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: 'josefin_sansbold';
    src: url('../fonts/josefinsans/josefinsans-bold_0-webfont.woff2') format('woff2'),
         url('../fonts/josefinsans/josefinsans-bold_0-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

// Roboto
@font-face {
    font-family: 'roboto';
    src: url('../fonts/roboto/roboto-bold_0-webfont.woff2') format('woff2'),
         url('../fonts/roboto/roboto-bold_0-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'roboto';
    src: url('../fonts/roboto/roboto-light_0-webfont.woff2') format('woff2'),
         url('../fonts/roboto/roboto-light_0-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'roboto';
    src: url('../fonts/roboto/roboto-lightitalic_0-webfont.woff2') format('woff2'),
         url('../fonts/roboto/roboto-lightitalic_0-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;

}

@font-face {
    font-family: 'roboto';
    src: url('../fonts/roboto/roboto-black_0-webfont.woff2') format('woff2'),
         url('../fonts/roboto/roboto-black_0-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;

}


// Merriweather
@font-face {
    font-family: 'merriwheather';
    src: url('../fonts/merriwheather/merriweather-bolditalic-webfont.woff2') format('woff2'),
         url('../fonts/merriwheather/merriweather-bolditalic-webfont.woff') format('woff');
    font-weight: bold;
    font-style: italic;

}

@font-face {
    font-family: 'merriwheather';
    src: url('../fonts/merriwheather/merriweather-regular-webfont.woff2') format('woff2'),
         url('../fonts/merriwheather/merriweather-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'merriwheather';
    src: url('../fonts/merriwheather/merriweather-light-webfont.woff2') format('woff2'),
         url('../fonts/merriwheather/merriweather-light-webfont.woff') format('woff');
    font-weight: 100;
    font-style: normal;

}

@font-face {
    font-family: 'merriwheather';
    src: url('../fonts/merriwheather/merriweather-lightitalic-webfont.woff2') format('woff2'),
         url('../fonts/merriwheather/merriweather-lightitalic-webfont.woff') format('woff');
    font-weight: 100;
    font-style: italic;

}

@font-face {
    font-family: 'merriwheather';
    src: url('../fonts/merriwheather/merriweather-italic-webfont.woff2') format('woff2'),
         url('../fonts/merriwheather/merriweather-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;

}