/**
 * brand component
 * Display by pages: brand.html
 * Display by partials: brand.html
 */
.brand__header {
	margin-top: 2rem;
	margin-bottom: 0.2rem;

	@include breakpoint(smedium) {
		margin-bottom: 1.6rem;
	}
}

.brand__button {
	@include project-button;
}

.brand__image {
	@include breakpoint(smedium down) {
		margin-bottom: 1.5rem;
	}

	img {
		width: 100%;
	}
}

.brand__products {
	margin-top: 2.8rem;
}

.brand__title {
	text-transform: uppercase;
}

.brand__w-button {
	margin-top: 2rem;
}
