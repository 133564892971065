/**
 * Category component
 */
.category {
	@include xy-grid-container;
	@include xy-grid;

	&--nopadding {
		@include xy-grid-container($padding: 0);
	}
}

.category__header {
	@include xy-cell(100%, $gutters: 0);
	@include flex;
	@include flex-align(justify);	
}

.category__image {
	@include breakpoint(small only) {
		@include xy-cell(100%, $gutters: 0);
	}

	@include breakpoint(smedium only) {
		@include xy-cell(50%, $gutters: 0);
	}

	@include breakpoint(medium up) {
		@include xy-cell(35%, $gutters: 0);
	}
}

.category__main {
	@include breakpoint(smedium) {
		@include xy-cell(auto, $gutters: 60px, $gutter-position: left);
	}

	&--withoutimage {
		@include breakpoint(smedium) {
			@include xy-cell(auto, $gutters: 0);
		}		
	}
}

.category__pagination {
	@include xy-cell(100%, $gutters: 0);
	@include xy-grid;
	@include flex-align(center);
}

.category__products {
	@include xy-grid;
	@include xy-cell(100%, $gutters: 0);
	@include grid-for('.product');
}