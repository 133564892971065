/**
 * Flash component
 */
.flash {
	@include xy-grid-container;
	@include xy-grid;
}

.flash__wrapper {
	@include xy-cell($gutters: 0);
	@include flex;
	@include flex-align(left, middle);
}