/**
 * Homereassurance component
 * Display by pages: index.html
 * Display by partials: homereassurance.html
 */
.homereassurance {
	@include project-sectiontitle;
	padding-top: 3rem;
	padding-bottom: 0.3rem;

	&::before {
		margin-bottom: 3rem;
	}
}

.homereassurance__header {
	margin-top: 0 !important;

	@include breakpoint(smedium) {
		margin-bottom: 0;
	}

	@include breakpoint(smedium down) {
		margin-top: 0;
	}

	@include breakpoint(small only) {
		margin-bottom: 1.5rem;
	}
}

.homereassurance__title {
	padding-top: 0 !important;
	display: flex;

	&:before {
		@include dotBefore();
	}

	@include breakpoint(small only) {
		max-width: 70%;
	}
}