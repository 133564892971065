/**
 * Address component
 * Display by pages: checkout-complete.html
 * Display by partials: checkout--complete.html
 */
.address {
	border: 1px solid $border-color;
	padding: 1rem 1rem 1.5rem;

	@include breakpoint(smedium) {
		padding: 1rem 2.6rem 2rem;
	}

	.button {
		display: block;
		width: 100%;		
	}
}

.address__line {
	&:last-of-type {
		margin-bottom: 1rem;
	}
}

.address__link {
	display: block;
	text-decoration: underline;
	margin-bottom: 10px;
	cursor: pointer;

	&--disable {
		color: $jaffa;
		cursor: unset;
		text-decoration: none;
	}
}

.address__title {
	font-family: $subtitle-font-family;
	font-size: rem-calc(20px);
	font-style: italic;	
	text-align: center;
	position: relative;
	padding-bottom: 0.7rem;
	margin-bottom: 2rem;

	&::after {
		content: "";
		height: 1px;
		width: 40%;
		background: $border-color;
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
	}
}