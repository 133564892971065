/**
 * Checkout component
 * Display by pages: checkout-address.html, checkout-complete.html
 * Display by partials: checkout--address.html, checkout--complete.html
 * Contexts :
 * &--complete : complete checkout step
 * &--address : address checkout step
 */
.checkout__address {
	.address:first-child {
		@include breakpoint(smedium down) {
			margin-bottom: 1rem;
		}
	}
}

.checkout__changepayment {
	margin-top: 4rem;
}

.checkout__comment {
	margin-top: 1.5rem;

	@include breakpoint(smedium) {
		margin-top: 2.5rem;
	}
}

.checkout__items {
	margin-top: 2rem;

	@include breakpoint(medium) {
		margin-top: 5.3rem;
	}
}

.checkout__previous {
	@include project-border-button($color: $body-font-color);
}

.checkout__resume {
	border: 1px solid $border-color;
	padding: 1.1rem 2rem;
	text-align: center;
	text-transform: uppercase;
	font-family: $title-font-family;
	font-size: rem-calc(18px);
	line-height: rem-calc(21px);

	&:first-child {
		@include breakpoint(small only) {
			margin-bottom: 1.5rem;
		}
	}
}

.checkout__resumelabel {
	&--creditcard, &--systempay {
		&::after {
			content: "";
			background: url('../../img/icons/creditcard.svg') no-repeat;
			width: 35px;
			height: 35px;
			margin-left: 1rem;
		}
	}
}

.checkout__submitbutton {
	@include project-button;
}

.checkout__subtitle {
	@include project-subtitle;
	margin-bottom: 1.5rem;

	@include breakpoint(smedium) {
		margin-bottom: 3.4rem;
	}
}

.checkout__title {
	@include project-title;
	margin-top: 2.6rem;
	margin-bottom: 1.5rem;

	@include breakpoint(smedium) {
		margin-bottom: 3.4rem;
		margin-top: 3.8rem;
	}
}

.checkout__w-resume {
	margin-top: 2.3rem;
	
	@include breakpoint(smedium) {
		margin-top: 3.2rem;
	}
}