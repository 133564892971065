/**
 * Homeproducts component
 * Display by pages: index.html
 * Display by partials: homeproducts.html
 */
.homeproducts {
	padding-top: 4rem;
	
	@include breakpoint (smedium down) {
		padding-top: 2rem;
	}

	&--with-notice {
		padding-top: 0;
	}
}

.homeproducts__header {
	margin-bottom: 2rem;
}

.homeproducts__searchbutton {
	@include project-negative-button;
	@include flex;
	@include flex-align(center, middle);

	&::after {
		content: "";
		background: url(../../img/icons/chevron.svg) no-repeat;
		width: 7px;
		height: 12px;
		margin-left: 0.5rem;
	}
}

.homeproducts__title {
	@include project-title;

	&:before {
		@include dotBefore();
	}
}