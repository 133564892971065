.cartsummary.cartsummary--products {
	.cartsummary__product {
		@include xy-grid;
	}

	.cartsummary__name {
		@include xy-cell(6, $gutters: 10px, $gutter-position: right)
	}

	.cartsummary__price {
		@include xy-cell(4, $gutters: 10px, $gutter-position: left)
	}
	.cartsummary__quantity {
		@include xy-cell(2, $gutters: 0)
	}
}