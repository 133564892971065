/**
 * Checkout component
 * &--connection : connection checkout step
 */
.checkout.checkout--connection {
	.checkout__title {
			text-align: center;
		span {
			text-transform: none;
			text-align: center;
			display: block;
		}
	}

	.checkout__submit {
		margin-top: 2rem;
		margin-bottom: 1rem;

		a {
			text-decoration: underline;
		}
	}

	.checkout__submitbutton {
		width: 60%;
	}
}