/**
 * Cartsummary component
 * &--complete : complete checkout step
 */
.cartsummary.cartsummary--complete {
	border: none;
	border-bottom: 1px solid $border-color;
	margin-top: 0.6rem;
	
	@include breakpoint(smedium down) {
		padding-left: 0;
	}

	@include breakpoint(medium) {
		padding-right: 6rem;
		padding-left: 0;
	}
}