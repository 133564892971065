.homenotice {
  @include xy-grid-container;
  @include xy-grid;

  display: flex;
  justify-content: center;

  @include breakpoint(smedium down) {
    @include xy-grid-container($padding: 0);
  }
}
