/**
 * Crosselling component
 * Display by pages: product.html
 * Display by partials: crosselling.html
 */
.crosselling {
	@include project-sectiontitle;
	margin-top: 5.8rem;
}

.crosselling__header {
	margin-top: 0.8rem;
}