/**
 * Homenetworks component
 */
.homenetworks {
	@include xy-grid;
	@include xy-cell(100%);

	@include breakpoint(smedium down) {
		@include xy-cell(100%, $gutters: 0);
	}
}

.homenetworks__content {
	@include xy-cell(6, $gutters: 0);

	@include breakpoint(smedium down) {
		@include xy-cell(45%, $gutters: 20px, $gutter-position: left);
	}
}

.homenetworks__image {
	@include xy-cell(46%, $gutter-position: left);

	@include breakpoint(smedium down) {
		@include xy-cell(55%, $gutters: 0);
	}
}

.homenetworks__title {
	&:not(&--responsive) {
		@include show-for(medium);
	}

	&--responsive {
		@include hide-for(medium);

		@include breakpoint(smedium down) {
			@include xy-cell(100%, $gutters: 0);
		}
	}
}