/**
 * Step component
 * Display by pages: checkout-address.html, checkout-complete.html
 * Display by partials: steps.html
 */
.step {
	font-family: $subtitle-font-family;
	font-weight: 100;
	font-style: italic;
	font-size: rem-calc(16px);
	color: $body-font-color;
	margin: 0 0.7rem;
	padding: 0.5rem 0;

	&--active {
		font-weight: bold;
		border-top: 1px solid $border-color;
		border-bottom: 1px solid $border-color;
	}
}

.step__chevron {
	background: url('../../img/icons/chevron.svg') no-repeat;
	width: 11px;
	height: 18px;
	margin: 0 0.5rem; 
}