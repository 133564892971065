.modal.modal--addtocart {
	.modal__aside {
		position: relative;
		&::before {
			content: "";
	    	width: 30px;
			height: 30px;
	    	display: block;
	    	background: url(../../img/icons/cart-white.svg) no-repeat;
	    	margin-left: auto;
	    	margin-right: auto;
	    	margin-bottom: 2rem;
		}
	}

	.modal__main {
		@include breakpoint(medium down) {
			margin-bottom: 2rem;
		}
	}
}