.tingle-modal__close {
	@include flex;
	@include flex-align(right, middle);
}

// Tingle modal box
.tingle-modal-box__footer {
	@include flex;
	@include flex-align(spaced, middle)
}

.tingle-modal__title { 
	@include xy-cell(12);
}

.tingle-modal__wrapper {
	@include xy-cell(12);
} 