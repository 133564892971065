/**
 * Cms component
 * Display by pages: cms.html
 */

.cms {
	margin-top: 5rem;
	
	@include breakpoint(smedium down) {
		margin-top: 2rem;
	}

	h1 {
		@include project-title(25px, 30px);
		margin-bottom: 1.8rem;
	}

	h2 {
		@include project-subtitle;
		font-size: rem-calc(20px);
		line-height: rem-calc(24px);
		margin-bottom: 1rem;
	}

	h3 {
		@include project-title(16px, 20px);
		margin-bottom: 1rem;
	}

	h4 {
		margin-bottom: 1rem;
		font-size: rem-calc(18px);
		line-height: rem-calc(20px);
	}
}

.cms__content {
	&:after {
		content: '';
		display: block;
		clear: both;
	}
}

.cms__figure {
	display: inline-block;
	position: relative;

	&.cms__figure--left {
		float: left;
		margin: 0 2rem 1rem 0;

		@include breakpoint(small only) {
			margin-right: 0;
		}
	}

	&.cms__figure--right {
		float: right;
		margin: 0 0 1rem 2rem;
	}

	figcaption {
		background: rgba(0, 0, 0, 0.5);
		color: white;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: 0.5rem;
		font-weight: 100;
		font-style: italic;
		text-align: center;
	}
}