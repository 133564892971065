/**
 * Account component
 */
.account {
	@include breakpoint (small only) {
		margin-top: 2rem;
	}

	&--withmargintop {
		margin-top: 3rem;
	}
}

.account__addresses {
	.address {
		margin-bottom: 1rem;
	}
}

.account__actions {
	a:first-child {
		@include project-negative-button;
	}
}
.account__backbutton {
	position: absolute;
	top: 1rem;
	right: 1rem;

	&::before {
		@include breakpoint (small only) {
			font-size: rem-calc(24px);
			content: "\2715";
			margin-right: 0;
		}
	}
}

.account__button {
	i {
		display: none;
	}

}

.account__column {
	h4 {
		margin-bottom: 1.5rem;
	}

	&:last-child {
		@include breakpoint(smedium down) {
			margin-top: 2rem;
		}
	}
}

.account__createaddress {
	@include project-button;
	margin-bottom: 2.5rem;
}

.account__menu {
	margin-top: 0.8rem;
	margin-bottom: 0.8rem;

	@include show-for(medium);
		
	@include breakpoint(smedium) {
		margin-top: 3rem;
		margin-bottom: 3rem;
	}

	@include breakpoint(small only) {
		color: white;
	}
}

.account__menumobile {
	a {
		color: white;
	}
}

.account__menuitem {
	font-family: $subtitle-font-family;
	font-weight: 100;
	font-style: italic;
	font-size: rem-calc(16px);
	position: relative;
	margin-right: 3rem;
	padding: 1rem;
	font-size: rem-calc(14px);

	@include breakpoint(smedium only) {
		margin-right: 0.5rem;
		font-size: rem-calc(12px);
		padding: 0.5rem;
	}

	&--active {
		font-weight: bold;
		border-top: 1px solid $border-color;
		border-bottom: 1px solid $border-color;
	}

	&:last-child {
		margin-right: 0;
	}
		
	&:not(:last-child) {
		&::after {
			@include breakpoint(medium) {
				content: "~";
	    		position: absolute;
	    		right: -1.6rem;
	    		top: 50%;
	    		transform: translateY(-50%);
	    		font-weight: bold;
			}

		}
	}
}

.account__menumobileitem {
	font-family: $subtitle-font-family;
	font-weight: 100;
	font-style: italic;
	font-size: rem-calc(16px);
	position: relative;
	padding: 1rem;
	font-size: rem-calc(14px);
	display: inline-block;

	&--active {
		font-weight: bold;
		&::before {
			content: ">";
			margin-right: 0.5rem;
		}
	}
}

.account__menutitle {
	font-family: $subtitle-font-family;
	font-size: rem-calc(20px);
	font-style: italic;	
	text-align: center;
	position: relative;
	padding-bottom: 0.7rem;
	margin-bottom: 2rem;
	font-weight: normal;	

	&::after {
		content: "";
		height: 1px;
		width: 40%;
		background: $border-color;
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
	}
}

.account__orders {
	a.button {
		margin-bottom: 0;
		i {
			display: none;
		}

		&:first-of-type {
			@include project-border-button;
			margin-bottom: 0;
		}
	}

	table {
		width: 100%;

		.item {
			text-align: center;
		}
	}
}

.account__previous {
	border: 1px solid $border-color;
	color: $body-font-color;
	@include project-border-button;
}

.account__submitbutton {
	@include project-button;
}

.account__subtitle {
	@include project-subtitle;
	margin-bottom: 1.5rem;

	@include breakpoint(smedium) {
		margin-bottom: 3.4rem;
	}
}

.account__title {
	@include project-title;
}