.modal {
	@include xy-grid-container($padding: 0);
	@include xy-grid;
}

.modal__back {
	@include flex;
	@include flex-align(left, middle);
}

.modal__w-aside {
	@include xy-cell(4);

	@include breakpoint(medium only) {
		@include xy-cell(4);
	}

	@include breakpoint(smedium down) {
		@include xy-cell(12);
	}
}

.modal__header {
	@include xy-cell(12);
	@include flex;
	@include flex-align(justify, middle);
}

.modal__main {
	@include xy-cell(auto);

	&--mobile {
		@include xy-cell(auto, $gutters: 0);
	}
}